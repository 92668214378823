import React, { useState, useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { SORT_BY_STRING } from '@ubeya/shared/utils/sorting';
import useCRM from '@ubeya/shared/hooks/account/useCRM';
import useFilters from '@ubeya/shared/hooks/account/useFilters';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';

const EmptyOptions = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
`;

const WorkedWithClientFilter = ({ basicFilters = [], defaultValue, handleClose, onApply, search, onChange }) => {
  const { t } = useContext(ConfigContext);
  const { clients, mappedClients } = useCRM();
  const [values, setValues] = useState(defaultValue || []);
  const { filters: generalFilters } = useFilters();

  const restrictedClientFilterInfo = (generalFilters?.employees?.basic || []).find(
    ({ slug }) => slug === 'restrictedClient'
  );

  const preSelectedClientId = basicFilters.find(({ id }) => id === restrictedClientFilterInfo?.id)?.value;

  const clientsOptions = useMemo(
    () =>
      (clients || [])
        /* filter the pre selected event client if exists */
        .filter(({ id }) => (preSelectedClientId ? id !== preSelectedClientId : true))
        .map(({ id, name }) => ({ value: id, label: name })),
    [clients, preSelectedClientId]
  );

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      (
        (search
          ? clientsOptions.filter(
              ({ label }) =>
                t('workedWithClient').toLowerCase().includes(search.toLowerCase()) ||
                label.toLowerCase().includes(search.toLowerCase())
            )
          : clientsOptions) || []
      ).sort(SORT_BY_STRING('label')),
    [clientsOptions, search, t]
  );

  /* if theres a tagged client put it first in the list */
  const filteredOptionsWithSelectedClient = preSelectedClientId
    ? [{ value: preSelectedClientId, label: mappedClients[preSelectedClientId].name }, ...filteredOptions]
    : filteredOptions;

  if (filteredOptions?.length === 0) {
    return null;
  }

  return (
    <FilterLayout
      name={t('workedWithClient')}
      handleClose={handleClose}
      onApply={() => onApply(values)}
      hideLayout={!!search}>
      {filteredOptionsWithSelectedClient && filteredOptionsWithSelectedClient?.length > 0 ? (
        filteredOptionsWithSelectedClient.map(({ value, label }) => (
          <DropDown.Item key={value}>
            <CheckBox
              name={`worked-with-client-${value}`}
              value={values.includes(value)}
              label={label}
              onChange={() => handleChange(value)}
            />
          </DropDown.Item>
        ))
      ) : (
        <EmptyOptions>{t('noOptions')}</EmptyOptions>
      )}
    </FilterLayout>
  );
};

export default WorkedWithClientFilter;
