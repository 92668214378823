import styled, { css } from 'styled-components';
import { ReactComponent as SortIcon } from '../assets/sort.svg';

export const SortButtons = styled.div`
  display: ${({ isActive }) => (isActive ? 'inline' : ' none;')};
  margin-left: 7px;
`;

export const SortArrow = styled(SortIcon)`
  margin-left: 1px;
  cursor: pointer;
  transform: rotate(${({ direction }) => (direction === 'desc' ? 0 : 180)}deg);
  path {
    ${({ theme, $isActive }) => $isActive && `fill: ${theme.colors.gray200};`}
  }
`;

export const ResizeHandler = styled.span`
  cursor: col-resize;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
`;

export const CellWrapper = styled.div.attrs(({ column }) => ({
  className: `cell ${column.frozen ? 'frozen' : ''} ${column.isLastFrozenColumn ? 'last-frozen' : ''}`
}))`
  contain: strict;
  contain: size layout style paint;
  padding: 0 8px;
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  background-color: inherit;
  white-space: nowrap;
  overflow: hidden;
  overflow: clip;
  text-overflow: ellipsis;

  width: 100%;
  height: 100%;

  &:hover ${SortButtons} {
    display: inline;
  }

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  ${({ column }) =>
    column.frozen
      ? css`
          position: sticky;
          z-index: 1;
          left: var(--frozen-left-${column.dataKey});
          ${column.isLastFrozenColumn &&
          css`
            box-shadow: 5px 0 7px rgba(0, 0, 0, 0.05);
          `}
        `
      : css`
          grid-column-start: ${column.idx + 1};
        `};
`;
