import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import DropDown from '../../DropDown';
import BaseRatingBar from '../../RatingBar';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';

const RatingBar = styled(BaseRatingBar)`
  width: 100%;
  > div {
    justify-content: space-between;
    > svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const RatingFilter = ({ defaultValue, handleClose, onApply, search }) => {
  const { t } = useContext(ConfigContext);
  const [rating, setRating] = useState(defaultValue || 0);

  if (search && !t('rating').toLowerCase().includes(search.toLowerCase())) {
    return null;
  }

  return (
    <FilterLayout
      name={t('rating')}
      handleClose={handleClose}
      hideLayout={!!search}
      onApply={() => (rating ? onApply(rating) : handleClose())}>
      <DropDown.Item>
        <RatingBar rating={rating} onChange={setRating} />
      </DropDown.Item>
    </FilterLayout>
  );
};

export default RatingFilter;
