export const CHAT_FILTERS_SLUGS = {
  tags: 'chatTagsFilter',
  branches: 'chatBranchesFilter',
  statuses: 'chatStatusesFilter'
};

export const PROJECT_LIVE_VIEW_FILTERS_SLUGS = {
  positions: 'projectLiveViewPositions',
  locations: 'projectLiveViewLocations',
  startTime: 'projectLiveViewStartTime'
};

export const PROJECT_SCHEDULING_FILTERS_SLUGS = {
  positions: 'projectSchedulingPositions',
  locations: 'projectSchedulingLocations',
  startTime: 'projectSchedulingStartTime',
  endTime: 'projectSchedulingEndTime',
  bookingStatus: 'projectSchedulingBookingStatus',
  suppliers: 'projectSchedulingSuppliers'
};

export const NON_BOOKED_BOOKING_STATUS = 1;
export const PARTIALLY_BOOKED_BOOKING_STATUS = 2;
export const FULLY_BOOKED_BOOKING_STATUS = 3;

export const BOOKING_STATUS_MAPPING = {
  [NON_BOOKED_BOOKING_STATUS]: 'nonBooked',
  [PARTIALLY_BOOKED_BOOKING_STATUS]: 'partiallyBooked',
  [FULLY_BOOKED_BOOKING_STATUS]: 'fullyBooked'
};
