import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ConfigContextProvider, contextDefaultValues } from '@ubeya/shared-web/contexts/ConfigContext';
import Presentation from './Presentation';

const AppShell = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const configContext = useMemo(() => ({ ...contextDefaultValues, t, theme }), [t, theme]);

  return (
    <ConfigContextProvider config={configContext}>
      <Switch>
        <Route path="/accounts/:accountId/projects/:projectId" component={Presentation} />
      </Switch>
    </ConfigContextProvider>
  );
};

export default AppShell;
