import React from 'react';
import 'rc-tooltip/assets/bootstrap_white.css';

const TooltipBody = ({ body, maxWidth, center }) => {
  return (
    <div
      style={{
        maxWidth: maxWidth ? `${maxWidth}px` : 'auto',
        textAlign: center ? 'center' : 'auto'
      }}>
      {body}
    </div>
  );
};

export default TooltipBody;
