export const UBEYA_INTERNAL_ACCOUNT_ID = 8;
export const DYNAMO_ACCOUNT_ID = 147;
export const ETZ_HASADE_ACCOUNT_ID = 262;
export const ARC_ACCOUNT_ID = 179;
export const RH_ACCOUNT_ID = 3276;
export const UBEYA_ON_DEMAND_ACCOUNT_ID = 6786;
export const DESTINO_ACCOUNT_ID = 23051;
export const PRO_DRINKS_ACCOUNT_ID = 10355;
export const ALPHA_ACCOUNT_ID = 6673;
export const DROR_DEMO_ACCOUNT_ID = 11571;
export const SPICE_ACCOUNT_ID = 158;
export const LA_MORRA_PIZZA_ACCOUNT_ID = 22796;
export const THE_ALL_STAR_AGENCY_ACCOUNT_ID = 12398;
export const MONTPERI_ACCOUNT_ID = 10988;
export const YORDEI_HASIRA_ACCOUNT_ID = 1795;
export const FRIENDS_ACCOUNT_ID = 12510;
export const LIMELIGHT_ACCOUNT_ID = 10153;
export const MERCO_ACCOUNT_ID = 13354;
export const PERCENT_110_ACCOUNT_ID = 4566;
export const EVENTO_ACCOUNT_ID = 4571;
export const PREMIUM_EVENT_STAFF_ACCOUNT_ID = 15880;
export const SUPPORT_NINJA_ACCOUNT_ID = 6652;
export const TEMP_STAFF_ACCOUNT_ID = 10504;
export const FENIX_CARE_ACCOUNT_ID = 17580;
export const TRING_CARE_ACCOUNT_ID = 18765;
export const PRICELESS_ONE_ACCOUNT_ID = 18855;
export const OBE_ACCOUNT_ID = 13705;
export const CONSTELATION_NL_ACCOUNT_ID = 31701;
export const JCA_ACCOUNT_ID = 32624;
export const ONB_ACCOUNT_ID = 22393;
export const GLOWTOUCH_ACCOUNT_ID = 21538;
export const CONSTELATION_ACCOUNT_ID = 23040;
export const CONSTELATION_TEST_ACCOUNT_ID = 19139;
export const CONSTELLATION_IRELAND_ACCOUNT_ID = 35003;
export const CONSTELLATION_JOCKEY_ACCOUNT_ID = 35002;
export const CHEFS_GARNED_CATERING_AND_EVENTS_ACCOUNT_ID = 2587;
export const XERO_FORMAT_ACCOUNT_IDS = [1, 8, 16636, 6673, 18351, 11253, 11571, 25440];
export const KAPAIM_ACCOUNT_ID = 24964;
export const DELAWARE_NORTH_ID = 32232;
export const FIRE_GROUP_ACCOUNT_ID = 25440;
export const HULLKR_ACCOUNT_ID = 27141;
export const BRENTFORD_ACCOUNT_ID = 24394;
export const VCP_ACCOUNT_ID = 25039;
export const BAXTER_STOREY_ACCOUNT_ID = 32934;
export const LEVY_TRAINING_ACCOUNT_ID = 35763;

export const CONSTELLATION_ACCOUNT_IDS = [
  CONSTELATION_ACCOUNT_ID,
  CONSTELATION_TEST_ACCOUNT_ID,
  CONSTELLATION_IRELAND_ACCOUNT_ID,
  CONSTELLATION_JOCKEY_ACCOUNT_ID,
  DELAWARE_NORTH_ID,
  LEVY_TRAINING_ACCOUNT_ID
];

export const ALLOW_ADDRESS_EDITABLE_EMPLOYEE_ACCOUNT_IDS = [8, 12812, 179, 25001, 12398, 30951, 27794, 11520];

export const DISABLE_CLIENT_SELECT_IN_ORDER = [BAXTER_STOREY_ACCOUNT_ID];

export const ALLOW_PROJECT_LIVE_VIEW_ACCOUNT_IDS = [
  JCA_ACCOUNT_ID,
  BRENTFORD_ACCOUNT_ID,
  CONSTELATION_ACCOUNT_ID,
  CONSTELATION_NL_ACCOUNT_ID,
  HULLKR_ACCOUNT_ID,
  VCP_ACCOUNT_ID,
  DELAWARE_NORTH_ID,
  LEVY_TRAINING_ACCOUNT_ID,
  ARC_ACCOUNT_ID
];

// 3562 - access dmc
const ACCESS_DMC_ACCOUNT_ID = 3562;
export const COASTAL_FORMAT_ACCOUNT_IDS = [ACCESS_DMC_ACCOUNT_ID];

const RONIT_FARM_DAILY_ACCOUNT_ID = 22981;
export const INCLUDE_DUMMY_PAYROLL = [RONIT_FARM_DAILY_ACCOUNT_ID];

export const ACCOUNTS_BLOCK_BOOKING_NO_POSITION = [35002];

export const ALLOWED_ACCOUNTS_FOR_SALARY_RESOURCING_EDGE_REPORT = [THE_ALL_STAR_AGENCY_ACCOUNT_ID, 36599];
