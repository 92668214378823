import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { viewAsClient } from '../../atoms/shared';
import * as api from '../../services/api';

const BASE_AUTH_URL = process.env.AUTH_URL;

const useUser = (options) => {
  const queryClient = useQueryClient();

  const [ubeyaAdminViewAsClient, setUbeyaAdminViewAsClient] = useRecoilState(viewAsClient);
  const storeKey = 'user';

  const { isLoading, isSuccess, data, refetch } = useQuery(storeKey, api.fetchUser, {
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options
  });

  const logout = useCallback(
    async (type) => {
      queryClient.clear();
      window.location = `${BASE_AUTH_URL}/logout/${type}`;
    },
    [queryClient]
  );

  return {
    isLoading,
    ubeyaAdminViewAsClient,
    setUbeyaAdminViewAsClient,
    isAuth: isSuccess,
    language: data?.language || 'en',
    accounts: data?.accounts,
    data: data || {},
    logout,
    refetch,
    isUbeyaAdmin: data?.isUbeyaAdmin
  };
};

export default useUser;
