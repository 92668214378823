import { useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import createCachedSelector from 're-reselect';
import { EMPLOYEES_TABS } from '../../constants';
import useAccount from '../account/useAccount';
import useBranches from '../account/useBranches';
import * as api from '../../services/api';
import usePagination from '../usePagination';

const selector = createCachedSelector(
  (data) => data,
  (data) => {
    const resolvedData = data || {};

    const formattedData = (resolvedData?.data || []).map((item) => ({
      ...item,
      fields: item.fields.reduce((all, curr) => ({ ...all, [curr.id]: curr.value }), {})
    }));

    const mappedEmployees = (formattedData || []).reduce((all, item) => {
      all[item.id] = item;
      return all;
    }, {});

    return { resolvedData, formattedData, mappedEmployees };
  }
)({
  keySelector: (_, storeKey) => `${storeKey.join('#')}`
});

const useEmployees = ({
  projectId,
  withWarnings,
  activeTab,
  search,
  filters,
  branches: selectedBranches,
  excludeIds,
  whitelistIds,
  onlyAdmins,
  pageSize = 50,
  enabled = true,
  includeAdmin = false,
  attachLastComment = false
  // isShift
} = {}) => {
  const isShift = undefined;
  const { accountId } = useAccount();
  const { branches } = useBranches();

  const paginationParams = useMemo(
    () => ({
      defaultParams: { sortBy: 'name', sortDirection: 'asc' },
      params: {
        accountId,
        pageSize,
        activeTab,
        search,
        filters,
        isShift
      }
    }),
    [accountId, pageSize, activeTab, search, filters, isShift]
  );

  const { page, setPage, state, setState } = usePagination(paginationParams);

  const queryClient = useQueryClient();

  const params = {
    page,
    pageSize: state.pageSize,
    accountId: state.accountId,
    ...EMPLOYEES_TABS[state.activeTab],
    searchTerm: state.search,
    isShift: state.isShift,
    orderBy: [{ col: state.sortBy, dir: state.sortDirection }],
    branches: selectedBranches || branches.map(({ id }) => id),
    filters: state.filters,
    whitelistIds,
    excludeIds,
    projectId,
    withWarnings,
    includes: includeAdmin ? ['withAdmins'] : undefined,
    onlyAdmins: onlyAdmins !== undefined ? onlyAdmins : EMPLOYEES_TABS[state.activeTab]?.onlyAdmins,
    attachLastComment
  };

  const storeKey = ['employees', params];

  const { isLoading, data, isFetching, refetch } = useQuery(storeKey, () => api.fetchEmployees(params), {
    staleTime: 0,
    cacheTime: 0,
    enabled: enabled && branches.length > 0 && state.activeTab !== 'candidates',
    keepPreviousData: true,
    select: (employeesData) => selector(employeesData, storeKey)
  });

  const { resolvedData = [], mappedEmployees = {}, formattedData = [] } = data || {};

  const { mutateAsync: exportEmployees } = useMutation(
    ({ toSendMail } = {}) => api.exportEmployees({ toSendMail, accountId, ...params }),
    {
      throwOnError: true
    }
  );

  const { mutateAsync: updateBulkEmployees } = useMutation(
    async ({ employees }) => {
      /* results array will contain the values of resolved promises and null for rejected promises */
      let results = [];

      /*  This ensures that Promise.all always resolves with an array of values, 
      even if some of the promises fail */
      await Promise.all(
        employees.map((values) => api.updateEmployee({ accountId, ...values })).map((p) => p.catch(() => null))
      ).then((res) => {
        results.push(...res.filter((curr) => !!curr));
      });

      if (results.length > 0) {
        return results;
      }

      /* if non of the options succeeded */
      throw new Error('');
    },
    {
      onSuccess: (_, { refetchEmployees = true }) => {
        if (refetchEmployees) {
          queryClient.invalidateQueries('employees');
          queryClient.invalidateQueries('employeesStats');
        }
      }
    }
  );

  return {
    page,
    setPage,
    pageSize,
    totalItems: resolvedData?.total,
    data: formattedData,
    sort: state,
    setSort: setState,
    isLoading: isLoading || isFetching,
    refetch,
    exportEmployees,
    updateBulkEmployees,
    mappedEmployees
  };
};

export default useEmployees;
