import { useMemo } from 'react';
import { useQuery } from 'react-query';
import * as api from '../../services/api';

const usePresentation = ({ accountId, projectId, presentationHash }) => {
  const storeKey = useMemo(() => ['presentation', accountId, projectId], [accountId, projectId]);

  const { isLoading, data } = useQuery(
    storeKey,
    () => api.fetchPresentations({ accountId, projectId, presentationHash }),
    {
      staleTime: 0,
      cacheTime: Infinity
    }
  );

  return {
    isLoading,
    presentation: data?.data
  };
};

export default usePresentation;
