import React, { useState, useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';
import { FlexCenter } from '../../Flex/FlexCenter';
import { CHAT_FILTERS_SLUGS } from '../../../constants';

const EmptyOptions = styled(FlexCenter)`
  height: 20px;
`;

const ChatBranchesFilter = ({ defaultValue, handleClose, onApply, search, onChange, externalOptions }) => {
  const { t } = useContext(ConfigContext);
  const branchesOptions = (externalOptions[CHAT_FILTERS_SLUGS.branches] || []).map(({ id, name }) => ({
    value: id,
    label: name
  }));
  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];

      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      search
        ? (branchesOptions || []).filter(
            ({ label }) =>
              t('branches').toLowerCase().includes(search.toLowerCase()) ||
              label.toLowerCase().includes(search.toLowerCase())
          )
        : branchesOptions,
    [branchesOptions, search, t]
  );

  if (!filteredOptions.length) {
    return search ? <></> : <EmptyOptions>{t('noOptions')}</EmptyOptions>;
  }

  return (
    <FilterLayout name={t('branches')} handleClose={handleClose} onApply={() => onApply(values)} hideLayout={!!search}>
      {filteredOptions.map(({ value, label }) => (
        <DropDown.Item key={value}>
          <CheckBox
            name={`branch-${value}`}
            value={values.includes(value)}
            label={label}
            onChange={() => handleChange(value)}
          />
        </DropDown.Item>
      ))}
    </FilterLayout>
  );
};

export default ChatBranchesFilter;
