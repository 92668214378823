import React, { useState, useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { SORT_BY_STRING } from '@ubeya/shared/utils/sorting';
import useSuppliers from '@ubeya/shared/hooks/account/useSuppliers';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';

const EmptyOptions = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
`;

const SuppliersFilter = ({ defaultValue, handleClose, onApply, search, onChange }) => {
  const { t } = useContext(ConfigContext);
  const { suppliersOptions } = useSuppliers();
  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      (
        (search
          ? suppliersOptions.filter(
              ({ label }) =>
                t('suppliers').toLowerCase().includes(search.toLowerCase()) ||
                label.toLowerCase().includes(search.toLowerCase())
            )
          : suppliersOptions) || []
      ).sort(SORT_BY_STRING('label')),
    [suppliersOptions, search, t]
  );

  return (
    <FilterLayout name={t('suppliers')} handleClose={handleClose} onApply={() => onApply(values)} hideLayout={!!search}>
      {filteredOptions && filteredOptions?.length > 0 ? (
        filteredOptions.map(({ value, label }) => (
          <DropDown.Item key={value}>
            <CheckBox
              name={`supplier-${value}`}
              value={values.includes(value)}
              label={label}
              onChange={() => handleChange(value)}
            />
          </DropDown.Item>
        ))
      ) : (
        <EmptyOptions>{t('noOptions')}</EmptyOptions>
      )}
    </FilterLayout>
  );
};

export default SuppliersFilter;
