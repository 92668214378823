import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import useFields from '@ubeya/shared/hooks/account/useFields';
import useLocations from '@ubeya/shared/hooks/account/useLocations';
import ConfigContext from '../../../contexts/ConfigContext';
import Slider from '../../Slider';
import FilterLayout from '../FilterLayout';
import { FlexColumn } from '../../Flex';
import { Map, PlacesAutocomplete } from '../..';

const Container = styled.div`
  padding: 10px 10px 10px 10px;
`;

const Title = styled.div`
  margin: 10px 0 10px 2px;
`;
const MapWrapper = styled(FlexColumn)`
  width: 100%;
  height: 150px;
  margin: 15px 0 5px;
`;

const DistanceFromLocationFilter = ({
  id: fieldId,

  defaultValue,
  handleClose,
  filterContextParams,
  onApply,
  search,
  onChange
}) => {
  const { t, distanceUnit } = useContext(ConfigContext);
  const { mappedFields } = useFields();
  const [places, setPlaces] = useState(null);

  const [value, setValue] = useState(defaultValue || []);

  const { projectLocationId } = filterContextParams || {};
  const { mappedLocations } = useLocations();

  const location = useMemo(() => {
    if (projectLocationId && mappedLocations?.[projectLocationId]?.lat && mappedLocations?.[projectLocationId]?.lat) {
      return mappedLocations[projectLocationId];
    }
    if (places) {
      return { lat: places.lat, lng: places.lng, address: places.address };
    }
    return null;
  }, [mappedLocations, places, projectLocationId]);

  const handleChange = useCallback(
    (distance) => {
      const distanceObj = { lat: location.lat, lng: location.lng, distance, distanceUnit, address: location.address };
      setValue(distanceObj);
      onChange?.(distanceObj);
    },
    [distanceUnit, location, onChange]
  );

  const onApplyFilter = useCallback(() => {
    location ? onApply(value) : handleClose();
  }, [location, onApply, handleClose, value]);

  const mapLocation = useMemo(
    () =>
      location
        ? {
            title: `${t('location')}- ${location.name}`,
            lat: location.lat,
            lng: location.lng
          }
        : {},
    [location, t]
  );

  return (
    <FilterLayout
      name={mappedFields?.[fieldId]?.name}
      handleClose={handleClose}
      onApply={onApplyFilter}
      hideLayout={!!search}>
      <Container>
        {!projectLocationId && (
          <PlacesAutocomplete
            title={t('address')}
            value={places || ''}
            onChange={(address, placesLocation) => setPlaces({ address, ...placesLocation })}
            required
            placeholder={t('insertLocation')}
            usePortal
          />
        )}
        {location && (
          <>
            <Title> {t('distanceFromEventLocation')}</Title>
            <Slider
              value={value.distance}
              onChange={handleChange}
              min={1}
              max={150}
              valueSuffix={t(`distanceUnit_${distanceUnit}_short`)}
            />
            <MapWrapper>
              <Map markers={[mapLocation]} circleRadius={value.distance} zoom={11} />
            </MapWrapper>
          </>
        )}
      </Container>
    </FilterLayout>
  );
};

export default DistanceFromLocationFilter;
