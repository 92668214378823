import React from 'react';
import styled, { css } from 'styled-components';
import { FlexMiddle } from '../Flex';
import { body } from '../Typography';
import checkIcon from '../../assets/selected.svg';

const backgroundCss = css`
  &:before {
    border: 1px solid ${({ theme, disabled }) => (disabled ? theme.colors.gray800 : theme.colors.secondary)};
    background: ${({ theme, disabled }) => (disabled ? theme.colors.gray800 : theme.colors.secondary)};
  }
`;

const checkedCss = css`
  ${backgroundCss};
  &:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 16px;
    height: 16px;
    background: url(${checkIcon}) no-repeat;
    background-position: center 3px;
    background-size: 16px;
  }
`;

const indeterminateCss = css`
  ${backgroundCss};
  &:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 10px;
    width: 5px;
    height: 10px;
    border-top: 2px solid ${({ theme }) => theme.colors.surface};
  }
`;

const Wrapper = styled(FlexMiddle)`
  input[type='checkbox'] + label {
    ${body};
    display: inline-block;
    cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
    position: relative;
    padding-left: 25px;
    margin-right: 15px;
    min-height: 24px;

    &:before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 3px;
      left: 0;
      border: 1px solid
        ${({ theme, error, disabled }) =>
          disabled ? theme.colors.gray800 : error ? theme.colors.failure : theme.colors.gray400};
      background-color: #fff;
      border-radius: 1.6px;
    }
  }

  input[type='checkbox'] {
    visibility: hidden !important;

    &:checked + label {
      ${({ indeterminate }) => (indeterminate ? indeterminateCss : checkedCss)}
    }

    & + label {
      ${({ indeterminate }) => indeterminate && indeterminateCss}
    }
  }

  &:focus {
    outline: none;
    > label:before {
      box-shadow: ${({ theme }) => theme.colors.inputShadow};
    }
  }
`;

const CheckBox = ({ name, value, onChange, indeterminate, label, children, disabled, error, className }) => (
  <Wrapper
    value={value}
    tabIndex={0}
    className={className}
    disabled={disabled}
    indeterminate={indeterminate}
    onKeyPress={() => (disabled ? {} : onChange(!value, name))}
    error={error}>
    <input type="checkbox" id={name} checked={!!value} onChange={() => (disabled ? {} : onChange(!value, name))} />

    <label htmlFor={name}>{label || children}</label>
  </Wrapper>
);

export default CheckBox;
