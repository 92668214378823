import React, { useState, useMemo, useContext } from 'react';
import styled, { css } from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import { Header3, FlexColumn, FlexCenter, ImagesCarousel, Flex } from '@ubeya/shared-web/components';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import { findLinksInText, formatPhone } from '@ubeya/shared-web/utils/employee';

const Photos = styled(Flex).attrs(() => ({ className: 'employee-card-photos-container' }))`
  margin: 5px 10px;
  flex-wrap: wrap;
`;

const Photo = styled.img.attrs(() => ({ className: 'employee-card-small-photo' }))`
  width: 50px;
  height: 50px;
  margin: 5px;
  cursor: pointer;
  object-fit: cover;
  object-position: 50% 50%;
`;

const ProfilePhoto = styled.img.attrs(() => ({ className: 'employee-card-profile-photo' }))`
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: 50% 50%;
  background: url('${({ src }) => src}');
  background-size: cover;
  cursor: pointer;
  background-position: center;
  margin: 0 auto;
`;

const FieldsContainer = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Card = styled.div`
  background: ${({ theme }) => theme.colors.surface};
  box-shadow: 0 0 4px 1px #d0d0d0;
  margin: 20px;
  width: 300px;
  text-align: center;

  ${({ $isPriceLessOne }) =>
    $isPriceLessOne &&
    css`
      ${!isMobileOnly &&
      css`
        height: 600px;
      `}

      .employee-card-header-name {
        font-size: 18rem;
      }

      .employee-card-row-wrapper {
        margin: 0;
      }

      .employee-card-profile-photo {
        width: 150px;
        height: 150px;
      }

      .employee-card-small-photo {
        width: 40px;
        height: 40px;
        margin: 3px;
      }

      .employee-card-photos-container {
        justify-content: center;
      }
    `}
`;

const RowWrapper = styled(Header3).attrs(() => ({ className: 'employee-card-row-wrapper' }))`
  font-size: 13rem;
  width: 100%;
  color: #5d4d46;
  word-wrap: break-word;
  margin: 10px;
`;

const Wrapper = styled(FlexColumn)`
  position: relative;
`;

const Content = styled(FlexCenter)`
  position: relative;
  margin: 20px;
`;

const Name = styled.h1.attrs(() => ({ className: 'employee-card-header-name' }))`
  font-size: 20rem;
  color: #5d4d46;
`;

const PhoneWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const EmployeeHeader = ({ name }) => (
  <Wrapper>
    <Content>
      <Name>{name}</Name>
    </Content>
  </Wrapper>
);

const Row = ({ title, value }) => (
  <RowWrapper>
    <strong>{title}</strong>: {value}
  </RowWrapper>
);

const EmployeesCard = ({ firstName, lastName, image, photos, phone, fields, positions, isPriceLessOne }) => {
  const [isOpenIndex, setIsOpenIndex] = useState();
  const { t } = useContext(ConfigContext);

  const employeePhotos = useMemo(() => [image, ...(photos || [])], [image, photos]);
  const displayPhotos = useMemo(() => employeePhotos.map((item) => ({ source: item })), [employeePhotos]);

  return (
    <Card $isPriceLessOne={isPriceLessOne}>
      <EmployeeHeader
        name={`${firstName} ${lastName}${
          positions.length > 0 ? ` - ${positions.map((position) => t(position)).join(', ')} ` : ``
        }`}
      />

      {employeePhotos[0] && <ProfilePhoto src={employeePhotos[0]} onClick={() => setIsOpenIndex(0)} />}

      <Photos>
        {employeePhotos.length > 0 &&
          employeePhotos.map(
            (photo, index) => index > 0 && <Photo src={photo} key={index} onClick={() => setIsOpenIndex(index)} />
          )}
      </Photos>

      <FieldsContainer>
        {phone && (
          <PhoneWrapper>
            <Row title={t('phoneInHebrew')} value={formatPhone(phone)} key="phone" />
          </PhoneWrapper>
        )}

        {fields
          .filter(({ value }) => !!value)
          .map((field, index) => (
            <Row title={field?.title} value={findLinksInText(field?.value)} key={index} />
          ))}
      </FieldsContainer>

      <ImagesCarousel views={displayPhotos} currentIndex={isOpenIndex} onClose={() => setIsOpenIndex()} />
    </Card>
  );
};

export default EmployeesCard;
