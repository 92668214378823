import React from 'react';
import PropTypes from 'prop-types';
import useSafeState from '@ubeya/shared/hooks/useSafeState';
import styled, { css } from 'styled-components';
import { ReactComponent as MoreIcon } from '../../assets/menu.svg';
import { ReactComponent as MoreIconWhite } from '../../assets/menu-white.svg';
import BaseCircleIcon from '../CircleIcon';
import DropDown from '../DropDown';
import PromptClick from '../PromptClick/PromptClick';

const MENU_OFFSET = { top: 5, right: 10 };

const CircleIcon = styled(BaseCircleIcon)`
  ${({ $whiteIcon }) =>
    $whiteIcon &&
    css`
      &:hover {
        background: rgba(255, 255, 255, 0.35);
      }
    `}
`;

const ContextMenu = ({
  className,
  children,
  options = [],
  menuOffset,
  usePortal,
  openFromRight,
  hideIconBackground = false,
  maxHeight,
  scrollingContainer,
  controlledIsOpen = false,
  menuMinWidth,
  menuMaxWidth,
  useTextWithTooltip,
  whiteIcon = false,
  isActive = false,
  showSearch = false
}) => {
  const [promptOptions, setPromptOptions] = useSafeState();

  return (
    <>
      <DropDown
        showArrow={false}
        renderValue={
          children || (
            <CircleIcon
              icon={whiteIcon ? MoreIconWhite : MoreIcon}
              $whiteIcon={whiteIcon}
              hideBackground={hideIconBackground}
              isActive={isActive}
            />
          )
        }
        className={className}
        menuOffset={menuOffset ?? MENU_OFFSET}
        usePortal={usePortal}
        openFromRight={openFromRight}
        maxHeight={maxHeight}
        menuMinWidth={menuMinWidth}
        menuMaxWidth={menuMaxWidth}
        controlledIsOpen={controlledIsOpen}
        scrollingContainer={scrollingContainer}
        showSearch={showSearch}>
        {options
          .filter(({ visible }) => visible === undefined || visible)
          .map((option, index) => {
            const optionProps = {};
            if (option.onClick) {
              optionProps.onClick = (e) => (option.confirm ? setPromptOptions(option) : option.onClick(e));
            }
            return (
              <DropDown.Item
                key={index}
                internalCss={option.css}
                icon={option.icon}
                isDisabled={option.disabled}
                useTextWithTooltip={useTextWithTooltip}
                closeOnClick={option.closeOnClick}
                customTooltip={option.customTooltip}
                {...optionProps}>
                {option.label}
              </DropDown.Item>
            );
          })}
      </DropDown>

      {promptOptions && (
        <PromptClick
          title={promptOptions.confirmTitle}
          body={promptOptions.confirmBody}
          confirmationModalContainerStyle={promptOptions.confirmationModalContainerStyle}
          renderBody={promptOptions.confirmRenderBody}
          isOpen
          onClose={() => setPromptOptions()}
          onClick={promptOptions.onClick}
          confirmText={promptOptions.confirmText}
          showCancelButton={promptOptions.confirmCancelButton}
          usePortal={promptOptions.confirmPortal}
          isWaitingForResponse={promptOptions.confirmIsWaitingForResponse}
          blockTypeModal={promptOptions.confirmBlockTypeModal}
          disabledConfirmTitle={promptOptions.disabledConfirmTitle}
        />
      )}
    </>
  );
};

ContextMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      onClick: PropTypes.func,
      confirm: PropTypes.bool,
      confirmBody: PropTypes.string,
      confirmText: PropTypes.string,
      confirmPortal: PropTypes.bool
    })
  )
};

export default ContextMenu;
