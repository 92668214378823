import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Flex } from '../../Flex';
import { baseInputStyle } from '../../Input/InputStyle';
import Chip from './Chip';

const Wrapper = styled.div.attrs(() => ({ className: 'select-container' }))`
  position: relative;
  flex: 1;
  align-self: flex-start;
`;

const Container = styled(Flex).attrs(() => ({ className: 'select-chips' }))`
  flex-wrap: wrap;
`;

const Input = styled.input.attrs(() => ({ className: 'select-input' }))`
  ${baseInputStyle};
  flex: 1;
  background: transparent;
  border: none;
  min-height: 44px;

  background: transparent;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: no-drop;
  }

  &:hover:not(:focus, :disabled) {
    border: none;
  }

  &:focus {
    border: none;
  }
`;

const MultiValue = ({
  value,
  options,
  onClick,
  placeholder,
  search,
  setSearch,
  isDisabled,
  onRemoveChip,
  // eslint-disable-next-line
  name,
  onBlur,
  isOpen,
  ...restProps
}) => {
  const displayChips = useMemo(() => options.filter((option) => value.includes(option.value)), [options, value]);

  return (
    <Wrapper onClick={onClick}>
      <Container>
        {displayChips.map((chip) => (
          <Chip key={chip.value} {...chip} isDisabled={isDisabled} onRemove={onRemoveChip} />
        ))}

        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={isDisabled ? '' : placeholder}
          disabled={isDisabled}
          onBlur={(e) => {
            onBlur?.(e);
            !isOpen && setSearch('');
          }}
          {...restProps}
        />
      </Container>
    </Wrapper>
  );
};

export default MultiValue;
