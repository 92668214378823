import React, { useState, useCallback, useMemo, useContext } from 'react';
import useTranslatedOptions from '@ubeya/shared/hooks/useTranslatedOptions';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';

const EMPLOYEE_TYPE_OPTIONS = [
  { label: 'daily', value: 0 },
  { label: 'shift', value: 1 }
];

const EmployeeTypeFilter = ({ defaultValue, handleClose, onApply, search, onChange }) => {
  const { t } = useContext(ConfigContext);
  const employeeTypeOptions = useTranslatedOptions(EMPLOYEE_TYPE_OPTIONS);
  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      search
        ? employeeTypeOptions.filter(
            ({ label }) =>
              t('employeeType').toLowerCase().includes(search.toLowerCase()) ||
              label.toLowerCase().includes(search.toLowerCase())
          )
        : employeeTypeOptions,
    [employeeTypeOptions, search, t]
  );

  if (!filteredOptions.length) {
    return null;
  }

  return (
    <FilterLayout
      name={t('employeeType')}
      handleClose={handleClose}
      onApply={() => onApply(values)}
      hideLayout={!!search}>
      {filteredOptions.map(({ value, label }) => (
        <DropDown.Item key={value}>
          <CheckBox
            name={`employee-type-${value}`}
            value={values.includes(value)}
            label={label}
            onChange={() => handleChange(value)}
          />
        </DropDown.Item>
      ))}
    </FilterLayout>
  );
};

export default EmployeeTypeFilter;
