import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { API_DATE_FORMAT } from '@ubeya/shared/constants';
import FilterLayout from '../FilterLayout';
import { DatePickerInput } from '../../DatePicker';
import ConfigContext from '../../../contexts/ConfigContext';

const Wrapper = styled.div`
  margin: 20px 5px;
`;

const DateFilter = ({ defaultValue, handleClose, onApply, search, isDisabled = true }) => {
  const { t } = useContext(ConfigContext);
  const [date, setDate] = useState(defaultValue || moment().format(API_DATE_FORMAT));

  if (search) {
    return null;
  }

  return (
    <FilterLayout name={t('date')} handleClose={handleClose} onApply={() => onApply(date)} hideLayout={!!search}>
      <Wrapper>
        <DatePickerInput
          value={date}
          isDisabled={isDisabled}
          onChange={(value) => setDate(moment(value).format(API_DATE_FORMAT))}
          clearable={false}
          usePortal
          withDay
        />
      </Wrapper>
    </FilterLayout>
  );
};

export default DateFilter;
