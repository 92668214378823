import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import usePresentation from '@ubeya/shared/hooks/presentation/usePresentation';
import { useQueryParams } from '@ubeya/shared-web/hooks/useQueryParams';
import {
  header1SemiBold,
  smallSemiBold,
  Loader,
  FlexColumn,
  Masonry,
  flexCenter,
  Flex
} from '@ubeya/shared-web/components';
import { API_DATE_TIME_FORMAT } from '@ubeya/shared/constants';
import { PRICELESS_ONE_ACCOUNT_ID } from '@ubeya/shared/utils/accounts';
import { ReactComponent as BasePricelessOneDiamond } from '@ubeya/shared-web/assets/priceless-one/diamond.svg';
import { ReactComponent as BasePricelessOneLogo } from '@ubeya/shared-web/assets/priceless-one/priceless-one-logo.svg';
import { ReactComponent as BaseShapeOne } from '@ubeya/shared-web/assets/priceless-one/shape-a.svg';
import { ReactComponent as BaseShapeTwo } from '@ubeya/shared-web/assets/priceless-one/shape-b.svg';
import { ReactComponent as BaseShapeThree } from '@ubeya/shared-web/assets/priceless-one/shape-c.svg';
import { ReactComponent as BaseShapeFour } from '@ubeya/shared-web/assets/priceless-one/shape-d.svg';
import { isMobileOnly } from 'react-device-detect';
import EmployeesCards from './EmployeesCards';

const mobileView = css`
  width: 170px;
  height: 170px;
`;

const ShapeOne = styled(BaseShapeOne)`
  z-index: -10;
  position: absolute;
  top: 0;
  left: 0;

  ${isMobileOnly && mobileView}
`;

const ShapeTwo = styled(BaseShapeTwo)`
  z-index: -10;
  position: absolute;
  top: 0;
  right: 0;

  ${isMobileOnly && mobileView}
`;

const ShapeThree = styled(BaseShapeThree)`
  z-index: -10;
  position: absolute;
  bottom: 0;
  left: 0;

  ${isMobileOnly && mobileView}
`;

const ShapeFour = styled(BaseShapeFour)`
  z-index: -10;
  position: absolute;
  bottom: 0;
  right: 0;

  ${isMobileOnly && mobileView}
`;

const PricelessOneDiamond = styled(BasePricelessOneDiamond)`
  z-index: -10;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 300px;
  height: 300px;

  ${isMobileOnly && mobileView}
`;

const PricelessOneLogo = styled(BasePricelessOneLogo)`
  z-index: -10;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 300px;
  height: 300px;

  ${isMobileOnly && mobileView}
`;

const Container = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const MasonryContainer = styled(FlexColumn)`
  width: 100%;
  margin-top: 100px;

  .header-wrapper {
    align-self: center;
    text-align: center;
    margin: 0;
    padding: 0 24px;
    align-items: center;
  }
`;

const MasonryWrapper = styled.div`
  margin-top: 100px;
`;

const PricelessFooterText = styled.p`
  color: #252525;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
`;

const HeaderWrapper = styled.div.attrs(() => ({ className: 'header-wrapper' }))`
  ${header1SemiBold}
  font-size: 30rem;
  margin: 30px;
  text-transform: uppercase;
`;

const Logo = styled.div.attrs(({ image }) => ({ style: { backgroundImage: `url(${image})` } }))`
  ${flexCenter};
  ${smallSemiBold};
  position: absolute;
  right: 25px;
  top: 10px;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  background: ${({ theme }) => theme.colors.background};
  background-size: cover;
  background-position: center;
  color: ${({ theme }) => theme.colors.dark};
  border: 1px solid ${({ theme }) => theme.colors.gray800};
  text-align: center;
  margin: 0 auto 20px;
`;

const Header = ({ title, big }) => <HeaderWrapper $big={big}>{title}</HeaderWrapper>;

const Presentation = () => {
  const { accountId, projectId } = useParams();
  const { presentationHash } = useQueryParams();

  const { isLoading, presentation = {} } = usePresentation({
    accountId,
    projectId,
    presentationHash
  });

  const { project, logo } = presentation;

  const mappedFields = useMemo(
    () =>
      (presentation?.fields || []).reduce((acc, curr) => {
        acc[curr.id] = curr;

        return acc;
      }, {}),
    [presentation?.fields]
  );

  const formatFieldValue = useCallback(
    (id, value) => {
      if (!value) {
        return;
      }

      if (Array.isArray(value)) {
        const valueOptions = value.map(
          (optionId) => mappedFields[id]?.options?.find((option) => option.id === optionId)?.title
        );

        return valueOptions.join(', ');
      }

      if (moment(value, API_DATE_TIME_FORMAT, true).isValid()) {
        return moment(value, API_DATE_TIME_FORMAT).format(project?.dateFormat);
      }

      const mappedValue = mappedFields[id]?.options?.find((option) => option.id === value);

      if (mappedValue) {
        return mappedValue?.title;
      }

      return value;
    },
    [mappedFields, project?.dateFormat]
  );

  const getFieldsToDisplay = useCallback(
    (fields) =>
      fields
        .map(({ id, value }) => {
          const validFieldValue = formatFieldValue(id, value);

          return {
            title: mappedFields[id]?.title,
            value: validFieldValue
          };
        })
        .filter(({ value }) => (Array.isArray(value) ? value.length !== 0 : value)),
    [formatFieldValue, mappedFields]
  );

  const title = project?.labelId
    ? `${moment(project?.date).format('dddd')} -  ${project?.labelName}`
    : `${moment(project?.date).format(project?.dateFormat)} ${project?.name ?? ''} - ${project?.location} `;

  const isPriceLessOne = Number(accountId) === PRICELESS_ONE_ACCOUNT_ID;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      {isPriceLessOne && (
        <>
          <ShapeOne />
          <ShapeTwo />
          <PricelessOneDiamond />
          <PricelessOneLogo />
          <ShapeThree />
          <ShapeFour />
        </>
      )}
      <Logo image={logo}>{!logo && 'Branch Logo'}</Logo>

      <MasonryContainer>
        <Header title={title} big />

        <MasonryWrapper>
          <Masonry breakPoints={[0, 600, 1000, 1200, 1800]}>
            {(presentation?.employees || []).map(
              ({ firstName, lastName, image, phone, fields, photos, positions }, index) => (
                <EmployeesCards
                  key={index}
                  firstName={firstName}
                  positions={positions}
                  lastName={lastName}
                  image={image}
                  phone={phone}
                  photos={photos}
                  fields={getFieldsToDisplay(fields)}
                  isPriceLessOne={isPriceLessOne}
                />
              )
            )}
          </Masonry>

          {isPriceLessOne && (
            <PricelessFooterText>
              Please review your available options below by clicking to expand each profile. Once you've made your
              selections, please notify us and we will book your desired team.
              <br />
              Have a priceless day!
              <br />
              <br />
            </PricelessFooterText>
          )}
        </MasonryWrapper>
      </MasonryContainer>
    </Container>
  );
};

export default Presentation;
