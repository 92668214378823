import React, { useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import useSuppliers from '@ubeya/shared/hooks/account/useSuppliers';
import useCRM from '@ubeya/shared/hooks/account/useCRM';
import DropDown from '../../DropDown';
import BaseCheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';

const CheckBox = styled(BaseCheckBox)`
  flex: 1;
`;

const OrderStatusFilter = ({ defaultValue, handleClose, onApply, search, onChange }) => {
  const { t } = useContext(ConfigContext);
  const [values, setValues] = useState(defaultValue || []);
  const { hasSuppliers } = useSuppliers();
  const { hasClients } = useCRM();

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const options = [
    {
      label: t('filterOrderStatusApproved'),
      value: 'supplier_order_approved'
    },
    {
      label: t('filterOrderStatusPendingChanges'),
      value: 'changes_requested'
    },
    {
      label: t('filterOrderStatusPendingApproval'),
      value: 'pending_your_approval'
    },
    ...(hasClients
      ? [
          {
            label: t('filterOrderStatusPendingClientApproval'),
            value: 'client_order_pending_approval'
          }
        ]
      : []),
    ...(hasSuppliers
      ? [
          {
            label: t('filterOrderStatusPendingSupplierApproval'),
            value: 'supplier_order_pending_approval'
          }
        ]
      : []),
    {
      label: t('filterOrderStatusCanceledOrder'),
      value: 'order_canceled'
    }
  ];

  return (
    <FilterLayout
      name={t('orderStatusFilter')}
      handleClose={handleClose}
      onApply={() => onApply(values)}
      hideLayout={!!search}>
      {options.map(({ value, label }) => (
        <DropDown.Item key={value}>
          <CheckBox
            name={`order-status-${value}`}
            value={values.includes(value)}
            label={label}
            onChange={() => handleChange(value)}
          />
        </DropDown.Item>
      ))}
    </FilterLayout>
  );
};

export default OrderStatusFilter;
