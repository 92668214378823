import { plansModal } from '@ubeya/shared/atoms/shared';
import React, { useContext } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { ReactComponent as BaseSupplierFreemiumPlansIcon } from '@ubeya/shared-web/assets/supplier-freemium-plans.svg';
import ConfigContext from '../../contexts/ConfigContext';
import Container from '../Container';
import { FlexColumn, FlexMiddle, FlexSpace, flexSpace } from '../Flex';
import { Header2SemiBold } from '../Typography';
import RefreshBanner from '../RefreshBanner';

const Wrapper = styled(FlexColumn)`
  z-index: 1;
`;

const SupplierFreemiumPlansIcon = styled(BaseSupplierFreemiumPlansIcon)`
  &:hover {
    cursor: pointer;
  }
`;

const SupplierFreemiumBanner = styled.div`
  width: 100%;
  background-color: rgba(236, 239, 247, 1);
  height: 62px;
  margin: 20px 0;
  padding: 2%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BaseHeader = styled(Container)`
  ${flexSpace};
  background: ${({ theme }) => theme.colors.surface};
  min-height: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray900};
  margin-bottom: ${({ marginBottom, marginBottomValue }) => (marginBottom ? `${marginBottomValue || 30}px` : 'unset')};
`;

const FirstHeader = styled(BaseHeader)`
  z-index: ${({ marginBottom }) => (marginBottom ? 0 : 1)};
`;

const SecondHeader = styled(BaseHeader)`
  z-index: 0;
`;

const SupplierFreemiumHeader = styled(Container)`
  z-index: 0;
  padding: 0;
  margin: 0;
  ${flexSpace};
  background: ${({ theme }) => theme.colors.surface};
  height: 62px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray900};
`;

const Title = styled(Header2SemiBold).attrs(() => ({ className: 'header-title' }))`
  margin-right: 50px;
`;

const Combined = styled(FlexSpace)`
  flex: 1;
`;

const Header = ({
  title,
  leftSide,
  rightSide,
  children,
  className,
  marginBottom = true,
  marginBottomValue,
  showFirstHeader = true,
  firstHeaderContent,
  combined = false,
  showSupplierFreemiumBanner = false,
  showRefreshBanner = false
}) => {
  const { t } = useContext(ConfigContext);
  const setIsPlansModalOpen = useSetRecoilState(plansModal);

  const showSecondHeader = !combined && (leftSide || rightSide || children);

  const secondContent = (
    <>
      <FlexMiddle>{leftSide}</FlexMiddle>

      <FlexMiddle>{rightSide}</FlexMiddle>

      {children}
    </>
  );

  const supplierFreemiumContent = (
    <>
      <SupplierFreemiumBanner>
        {t('supplierFreemiumBannerText')}
        <SupplierFreemiumPlansIcon onClick={() => setIsPlansModalOpen(true)} />
      </SupplierFreemiumBanner>
    </>
  );

  return (
    <Wrapper className={className}>
      {showFirstHeader && (
        <FirstHeader marginBottom={combined && marginBottom} marginBottomValue={marginBottomValue}>
          {title && <Title>{title}</Title>}

          {firstHeaderContent}

          {combined && <Combined>{secondContent}</Combined>}
        </FirstHeader>
      )}

      {showRefreshBanner && <RefreshBanner />}

      {false && showSupplierFreemiumBanner && (
        <SupplierFreemiumHeader>{supplierFreemiumContent}</SupplierFreemiumHeader>
      )}

      {showSecondHeader && (
        <SecondHeader marginBottom={marginBottom} marginBottomValue={marginBottomValue}>
          {secondContent}
        </SecondHeader>
      )}
    </Wrapper>
  );
};

export default Header;
