import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FlexColumn, FlexMiddle } from '../Flex';
import pages from '../../utils/pagination';
import { smallBodySemiBold } from '../Typography';
import { ReactComponent as ArrowIcon } from '../../assets/arrow.svg';
import media from '../../style/media';
import { card } from '../Card';
import Table from './Table';

const Wrapper = styled(FlexColumn)`
  flex: 1;
  background: ${({ theme }) => theme.colors.surface};
`;

const Container = styled(FlexColumn)`
  flex: 1;
`;

const Footer = styled(FlexMiddle)`
  ${({ theme, shadow }) => (shadow ? card : `border: 1px solid ${theme.colors.gray900};`)}
  z-index: 1;
  justify-content: center;
  padding: 20px 0;

  ${media.mobile`justify-content: flex-end; padding: 15px;`}
`;

const Page = styled.button.attrs(({ $pageButtonType }) => $pageButtonType && { type: $pageButtonType })`
  margin: 0 8px;
  border-radius: 3px;
  padding: 2px 6px;
  ${smallBodySemiBold};
  background: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.surface)};
  color: ${({ theme, isActive }) => (isActive ? theme.colors.surface : theme.colors.gray200)};
  border: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'no-drop' : 'pointer')};
  outline: none;

  > svg {
    > path {
      fill: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.gray800 : theme.colors.gray200)};
    }
  }

  &:hover {
    > svg {
      ${({ isDisabled }) => !isDisabled && 'box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.35);'}
    }
  }
`;

const Arrow = styled(ArrowIcon).attrs(({ $reverse }) => ({ className: $reverse ? 'rotate-0' : 'rotate-180' }))`
  background: #fff;
  border-radius: 50%;
  padding: 5px 4px;
  width: 12px;
  margin-top: 3px;
`;

const PaginationTable = ({
  page,
  setPage,
  pageButtonType = null,
  pageSize = 50,
  totalItems,
  className,
  shadow = true,
  ...restProps
}) => {
  const totalPages = useMemo(() => Math.ceil(totalItems / pageSize), [pageSize, totalItems]);
  const pageNumbers = useMemo(() => pages({ selected: page, pageCount: totalPages }), [page, totalPages]);

  return (
    <Wrapper className={className}>
      <Container>
        <Table {...restProps} shadow={shadow} />
      </Container>

      {totalPages > 1 && (
        <Footer shadow={shadow}>
          <>
            <Page
              isDisabled={page === 1}
              $pageButtonType={pageButtonType}
              onClick={() => page > 1 && setPage(page - 1)}>
              <Arrow />
            </Page>

            {pageNumbers.map((_page, index) => {
              const pageNumberIsNotValid = isNaN(_page);
              return (
                <Page
                  key={index}
                  isDisabled={pageNumberIsNotValid}
                  $pageButtonType={pageButtonType}
                  isActive={_page === page}
                  onClick={() => (!pageNumberIsNotValid ? setPage(_page) : undefined)}>
                  {_page}
                </Page>
              );
            })}

            <Page
              isDisabled={page === totalPages}
              $pageButtonType={pageButtonType}
              onClick={() => page < totalPages && setPage(page + 1)}>
              <Arrow $reverse />
            </Page>
          </>
        </Footer>
      )}
    </Wrapper>
  );
};

export default PaginationTable;
