import React, { useContext } from 'react';
import styled from 'styled-components';
import { Body } from '../Typography';
import Button from '../Button';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import scrollbar from '../../style/scrollbar';
import ConfigContext from '../../contexts/ConfigContext';

const Title = styled(Body)`
  padding: ${({ hideLayout }) => (hideLayout ? '10px 20px 0' : '10px 20px 20px')};
  margin: 0 -10px 10px;
  ${({ theme, hideLayout }) =>
    hideLayout ? `border-top: 1px solid ${theme.colors.gray900};` : `border-bottom: 1px solid ${theme.colors.gray900};`}
  cursor: pointer;
  white-space: nowrap;
  max-width: 80vw;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BackArrow = styled(Arrow).attrs(() => ({ className: 'rotate-180' }))`
  margin-right: 12px;
  margin-left: 7px;
`;

const Container = styled.div.attrs(() => ({ className: 'filter-container' }))`
  max-height: 250px;
  overflow: auto;
  margin: -10px -10px;
  ${scrollbar};
`;

const ApplyButton = styled(Button)`
  border-top: 1px solid ${({ theme }) => theme.colors.gray900};
  margin: 10px -10px -10px;
`;

const FilerLayout = ({ name, handleClose, children, onApply, hideLayout }) => {
  const { t } = useContext(ConfigContext);
  return (
    <>
      <Title hideLayout={hideLayout} onClick={handleClose}>
        {!hideLayout && handleClose && <BackArrow />}
        {t(name)}
      </Title>

      <Container>{children}</Container>

      {!hideLayout && (
        <ApplyButton tertiary onClick={onApply}>
          Done
        </ApplyButton>
      )}
    </>
  );
};

export default FilerLayout;
