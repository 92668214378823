import React from 'react';
import { FlexMiddle } from '../../Flex';

const CostCenterChip = ({ externalOptions = {}, value }) => {
  const formattedValueData = value ? (Array.isArray(value) ? value : [value]) : [];

  return (
    <FlexMiddle>
      {(externalOptions.costCenter || [])
        .filter(({ id }) => formattedValueData.includes(id))
        .map(({ value: optionValue }) => optionValue)
        .join(', ')}
    </FlexMiddle>
  );
};

export default CostCenterChip;
