import React, { useContext, useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import useTimeout from '@ubeya/shared/hooks/useTimeout';
import BaseDataGrid from '../DataGrid';
import { TextWithTooltip } from '../Tooltip';
import { LoaderArea } from '../Loader';
import CircleIcon from '../CircleIcon';
import { ReactComponent as EditColumn } from '../../assets/edit.svg';
import { bodySemiBold } from '../Typography';
import { scrollbar } from '../../style';
import { card } from '../Card';
import CheckBox from '../CheckBox';
import ConfigContext from '../../contexts/ConfigContext';
import ColumnsMenu from './ColumnsMenu';

const DataGrid = styled(BaseDataGrid)`
  --color: ${({ theme }) => theme.colors.gray200};
  --border-color: ${({ theme }) => theme.colors.gray930};
  --background-color: ${({ theme }) => theme.colors.surface};
  --header-background-color: ${({ theme }) => theme.colors.surface};
  --row-hover-background-color: ${({ theme }) => theme.colors.background};
  ${scrollbar};

  ${({ shadow }) => (shadow ? card : `box-shadow: none;`)}
  ${({ autoHeight }) => autoHeight && `overflow: hidden;`}

  .header-row,.row {
    border-bottom: 1px solid var(--border-color);
  }

  #group {
    &:hover {
      background-color: ${({ theme }) => theme.colors.table.darkHover};
    }
  }

  #sub-row {
    &:hover {
      background-color: ${({ theme }) => theme.colors.table.lightHover};
    }
  }

  .cell {
    display: flex;
    align-items: center;
    ${({ noCellPadding }) => !noCellPadding && `padding: 10px;`}
    border: none;
    &.header {
      ${bodySemiBold};
      font-size: 13px;
    }
  }

  .cell :not(:first-child) {
    ${({ $showCellBorder }) =>
      $showCellBorder &&
      css`
        border-left: 1px solid var(--border-color);
        border-right: 0;
        &.header {
          border-left: 1px solid var(--border-color);
          border-right: 0;
        }
      `};
  }
`;

const DEFAULT_HEADER_ROW_HEIGHT = 38;
const DEFAULT_ROW_HEIGHT = 50;
const DEFAULT_IS_VIRTUALIZED = true;

const Table = ({
  autoHeight,
  shadow = true,
  noDataRows = 0,
  useCalculationBySubRows = false,
  isVirtualized = DEFAULT_IS_VIRTUALIZED,
  showCellBorder = true,
  extraTableActionIcon,
  extraTableActionHandler,
  CellRenderer,
  ...props
}) => {
  const headerRowHeight = props.headerRowHeight ?? DEFAULT_HEADER_ROW_HEIGHT;
  const rowHeight = props.rowHeight ?? DEFAULT_ROW_HEIGHT;
  const emptyRowDisplay = props.data.length === 0 ? noDataRows : 0;
  const [isLoading, setIsLoading] = useState(true);
  const { isRtl } = useContext(ConfigContext);

  const totalRows = useMemo(
    () =>
      props.data.length > 0
        ? props.data?.[0].subRows
          ? props.data?.[0].subRows.length * props.data.length
          : props.data.length
        : 0,
    [props]
  );

  useTimeout(() => setIsLoading(false), 500, [setIsLoading]);

  const checkIsVirtualized = useMemo(() => {
    if (typeof isVirtualized === 'boolean') {
      return isVirtualized;
    }
    if (typeof isVirtualized === 'function') {
      return isVirtualized(totalRows);
    }
    return DEFAULT_IS_VIRTUALIZED;
  }, [isVirtualized, totalRows]);

  if (isLoading) {
    return <LoaderArea />;
  }

  // when each item in data array contains sub rows, the real data length is calculated as following
  const autoHeightDataLength = useCalculationBySubRows
    ? props.data.reduce((all, { subRows }) => all + (subRows ? subRows.length : 0), 0)
    : 0;

  return (
    <DataGrid
      $showCellBorder={showCellBorder}
      shadow={shadow}
      autoHeight={autoHeight}
      isVirtualized={checkIsVirtualized}
      headerRowHeight={headerRowHeight}
      rowHeight={rowHeight}
      CellRenderer={CellRenderer || TextWithTooltip}
      loadingRenderer={() => <LoaderArea />}
      {...props}
      gridHeight={
        props.gridHeight ||
        (autoHeight
          ? (props.headless ? 0 : headerRowHeight) +
            (props.data.length + autoHeightDataLength + emptyRowDisplay) * rowHeight
          : undefined)
      }
      columnsActionListRenderer={({ onOpen }) => <CircleIcon icon={EditColumn} onClick={onOpen} />}
      extraTableActionIconRenderer={() => <CircleIcon icon={extraTableActionIcon} onClick={extraTableActionHandler} />}
      columnsMenuRenderer={(columnsMenuProps) => (
        <ColumnsMenu columnsMenuTitle={props.columnsMenuTitle} {...columnsMenuProps} />
      )}
      checkboxRenderer={CheckBox}
      isRtl={isRtl}
    />
  );
};

export default Table;
