import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import {
  CHAT_FILTERS_SLUGS,
  PROJECT_LIVE_VIEW_FILTERS_SLUGS,
  PROJECT_SCHEDULING_FILTERS_SLUGS
} from '@ubeya/shared-web/constants';
import { FILTERS_IDS } from '../../constants';
import * as api from '../../services/api';
import { uuidv4 } from '../../utils/uuid';
import useAccount from './useAccount';
import useFields, { FieldTypes } from './useFields';

const branchesFilterId = uuidv4();
const approvedFilterId = uuidv4();
const costCenterFilterId = uuidv4();

const FORECASTING_FILTERS_EVENTS_SULGS = ['clients'];
const TIMESHEET_FILTERS_EVENTS_SULGS = ['clients', 'eventBooker'];
const PAYROLL_FILTERS_EVENTS_SULGS = ['locations', 'clients', 'tags', 'eventBooker'];
const PAYRATES_FILTERS_EVENTS_SULGS = ['clients'];

const useFilters = () => {
  const { accountId } = useAccount();
  const { mappedFields } = useFields();
  const { enableClientCostCenter } = useContext(ConfigContext);

  const storeKey = ['filters', accountId];
  const { isLoading, data } = useQuery(storeKey, () => api.getFilters({ accountId }));

  const allFiltersData = useMemo(() => data?.data || {}, [data?.data]);

  const filters = useMemo(() => {
    const forcastingReportBasic = (allFiltersData?.events?.basic || [])?.filter(({ slug }) =>
      FORECASTING_FILTERS_EVENTS_SULGS.includes(slug)
    );

    forcastingReportBasic?.push({ id: branchesFilterId, slug: 'branches' });

    const timesheetBasic = [
      ...(allFiltersData?.employees?.basic || []),
      ...(allFiltersData?.events?.basic || []).filter(({ slug }) => TIMESHEET_FILTERS_EVENTS_SULGS.includes(slug))
    ];

    if (timesheetBasic) {
      timesheetBasic?.push({ id: approvedFilterId, slug: 'approved' });
    }

    const payrollBasic = [
      ...(allFiltersData?.employees?.basic || []).filter(({ id }) =>
        [FILTERS_IDS.POSITIONS_FILTER_ID, FILTERS_IDS.START_EMPLOYMENT_DATE].includes(id)
      ),
      ...(allFiltersData?.events?.basic || []).filter(({ slug }) => PAYROLL_FILTERS_EVENTS_SULGS.includes(slug))
    ];

    if (payrollBasic) {
      if (enableClientCostCenter) {
        payrollBasic.push({ id: costCenterFilterId, slug: 'costCenter' });
      }

      payrollBasic.push({ id: approvedFilterId, slug: 'approved' });
    }

    const projectSchedulingBasic = [
      { id: 1, kind: 'project-scheduling', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.positions },
      { id: 2, kind: 'project-scheduling', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.locations },
      { id: 3, kind: 'project-scheduling', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.startTime },
      { id: 4, kind: 'project-scheduling', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.endTime },
      { id: 5, kind: 'project-scheduling', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.bookingStatus },
      { id: 6, kind: 'project-scheduling', slug: PROJECT_SCHEDULING_FILTERS_SLUGS.suppliers }
    ];

    const projectLiveViewBasic = [
      { id: 1, kind: 'project-live-view', slug: PROJECT_LIVE_VIEW_FILTERS_SLUGS.positions },
      { id: 2, kind: 'project-live-view', slug: PROJECT_LIVE_VIEW_FILTERS_SLUGS.locations },
      { id: 3, kind: 'project-live-view', slug: PROJECT_LIVE_VIEW_FILTERS_SLUGS.startTime }
    ];

    const projectOrderStaffBasic = [
      { id: 1, kind: 'project-order-staff', slug: PROJECT_LIVE_VIEW_FILTERS_SLUGS.positions },
      { id: 2, kind: 'project-order-staff', slug: PROJECT_LIVE_VIEW_FILTERS_SLUGS.locations },
      { id: 3, kind: 'project-order-staff', slug: PROJECT_LIVE_VIEW_FILTERS_SLUGS.startTime }
    ];

    const chatBasic = [
      { id: 1, kind: 'chat', slug: CHAT_FILTERS_SLUGS.tags },
      { id: 2, kind: 'chat', slug: CHAT_FILTERS_SLUGS.branches },
      { id: 3, kind: 'chat', slug: CHAT_FILTERS_SLUGS.statuses }
    ];

    const employeesWTDFilteredFields = (allFiltersData?.employees?.fields || []).filter(
      (id) =>
        mappedFields?.[id]?.fieldTypeId === FieldTypes.FIELD_TYPE_OPTIONS ||
        mappedFields?.[id]?.fieldTypeId === FieldTypes.FIELD_TYPE_ADDRESS
    );

    const payRatesFields = {
      basic: [
        ...[
          { id: -1, kind: 'events', slug: 'branches' },
          { id: -2, kind: 'events', slug: 'positions' }
        ],
        ...(allFiltersData?.events?.basic || []).filter(({ slug }) => PAYRATES_FILTERS_EVENTS_SULGS.includes(slug))
      ],
      fields: []
    };

    return {
      ...allFiltersData,
      'forcasting-report': {
        basic: forcastingReportBasic,
        fields: []
      },
      timesheet: { basic: timesheetBasic, fields: allFiltersData?.employees?.fields },
      payroll: { basic: payrollBasic, fields: [] },
      'project-scheduling': {
        basic: projectSchedulingBasic,
        fields: []
      },
      'project-live-view': {
        basic: projectLiveViewBasic,
        fields: []
      },
      'project-order-staff': {
        basic: projectOrderStaffBasic,
        fields: []
      },
      chat: {
        basic: chatBasic,
        fields: []
      },
      'employees-wtd': { basic: [], fields: employeesWTDFilteredFields },
      payrates: payRatesFields
    };
  }, [allFiltersData, enableClientCostCenter, mappedFields]);

  return {
    isLoading,
    filters
  };
};

export default useFilters;
