import moment from 'moment';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { GROUP_BY_EMPLOYEE_VIEW, PROJECT_GROUP_BY_OPTIONS } from 'scheduling/constants';

const { persistAtom } = recoilPersist();

export const searchProjects = atom({ key: 'schedulingSearch', default: '' });

export const openShiftsExpand = atom({ key: 'schedulingOpenShiftsExpand', default: '' });

export const projectFilters = atom({ key: 'projectFilters', effects_UNSTABLE: [persistAtom] });

export const schedulingTimeRange = atom({ key: 'schedulingTimeRange' });

export const schedulingHourlyDate = atom({ key: 'schedulingHourlyDate', default: GROUP_BY_EMPLOYEE_VIEW.WEEKLY });

export const schedulingHourlyProjects = atom({ key: 'schedulingHourlyProjects', default: [] });

export const schedulingHourlyTimeOptions = atom({ key: 'schedulingHourlyTimeOptions', default: {} });

export const schedulingHourlyViewRefresh = atom({ key: 'schedulingHourlyViewRefresh', default: false });

export const schedulingMonthlyViewOption = atom({
  key: 'schedulingMonthlyViewOption',
  default: null
});

export const schedulingMonthlyViewTodayClicked = atom({
  key: 'schedulingMonthlyViewTodayClicked',
  default: false
});

export const schedulingCurrentlyAddedProjectId = atom({
  key: 'schedulingCurrentlyAddedProjectId',
  default: null
});

export const schedulingCurrentProjectHover = atom({
  key: 'schedulingCurrentProjectHover',
  default: null
});

export const schedulingGroupBy = atom({ key: 'schedulingGroupBy', default: 'shift' });

export const schedulingHourlyLightView = atom({ key: 'schedulingHourlyLightView', default: false });

export const schedulingStats = atom({ key: 'schedulingStats', default: false, effects_UNSTABLE: [persistAtom] });

export const sectionModal = atom({ key: 'schedulingSectionModal' });

export const copyShiftsModal = atom({ key: 'schedulingCopyShiftsModal' });

export const ordersSummaryModal = atom({ key: 'schedulingOrdersSummaryModal' });

export const publishShiftsInRangeModal = atom({ key: 'schedulingPublishShiftsInRangeModal' });

export const deleteShiftsInRangeModal = atom({ key: 'schedulingDeleteShiftsInRangeModal' });

export const availabilityModal = atom({ key: 'schedulingAvailabilityModal', default: false });

export const selectedShiftModal = atom({ key: 'schedulingSelectedShiftModal' });

export const selectedBookingModal = atom({ key: 'schedulingSelectedBookingModal' });

export const selectedEventModal = atom({ key: 'schedulingSelectedEventModal' });

export const selectedPublishProject = atom({ key: 'schedulingSelectedPublishProject' });

export const selectedPublicationStats = atom({ key: 'schedulingSelectedPublicationStats' });

export const selectedPublicationBatchStats = atom({ key: 'schedulingSelectedPublicationBatchStats' });

export const selectedCopyEvent = atom({ key: 'schedulingSelectedCopyEvent' });

export const editBulk = atom({ key: 'schedulingEditBulkItems', default: { isActive: false, items: [] } });

export const autoFillMode = atom({ key: 'schedulingAutoFillMode', default: false });

export const autoFillModal = atom({ key: 'schedulingAutoFillModal', default: false });

export const autoFillOpenShiftsModal = atom({ key: 'schedulingAutoFillOpenShiftsModal', default: false });

export const highlightMissingSlots = atom({ key: 'schedulingHighlightMissingSlots', default: false });

export const selectedConfirmationShiftIds = atom({ key: 'schedulingSelectedConfirmationShiftIds' });

export const addConfirmationShiftIds = atom({ key: 'schedulingAddConfirmationShiftIds' });

export const quickProject = atom({ key: 'schedulingQuickProject', default: false });

export const newProjectOrder = atom({ key: 'schedulingProjectNewOrder', default: false });

export const orderSuccess = atom({ key: 'schedulingOrderSuccess', default: false });

export const newOrder = atom({ key: 'schedulingtNewOrder', default: false });

export const schedulingShowMap = atom({ key: 'schedulingShowMap', default: false });

export const listView = atom({ key: 'schedulingListView', default: false });

export const listViewTodayButtonClicked = atom({ key: 'listViewTodayButtonClicked', default: false });

export const availableForSlotsView = atom({ key: 'schedulingAvailableForSlotsView', default: false });

export const schedulingArchived = atom({ key: 'schedulingArchived', default: false });

export const showAllBranchesMode = atom({ key: 'schedulingShowAllBranches', default: false });

export const schedulingActiveTab = atom({ key: 'activeTab', default: 'scheduling' });

export const schedulingOrderId = atom({ key: 'orderId', default: undefined });

export const schedulingHourlyCurrentDay = atom({ key: 'currentDay', default: moment().day() + 1 });

export const publishProjectsInRange = atom({ key: 'schedulingPublishProjectsInRange', default: false });

export const hourlyViewSortBy = atom({
  key: 'schedulingHourlyViewSortByPublishProjectsInRange',
  default: { sortBy: 'shifts', sortDirection: 'desc' }
});

export const hourlyWeeklyViewSortBy = atom({
  key: 'schedulingHourlyWeeklyViewSortByPublishProjectsInRange',
  default: { sortBy: 'shifts', sortDirection: 'desc' }
});

export const timeAvailabilityByEmployeeTimeRange = atom({
  key: 'timeAvailabilityByEmployeeTimeRange',
  default: 'weekly'
});

export const selectedAvailabilityPerDayModal = atom({ key: 'schedulingSelectedAvailabilityPerDayModal' });

export const schedulingProjectGroupBy = atom({
  key: 'schedulingProjectGroupBy',
  default: PROJECT_GROUP_BY_OPTIONS.DATE,
  effects_UNSTABLE: [persistAtom]
});

export const schedulingListViewSortBy = atom({
  key: 'schedulingListViewSortBy',
  default: { sortBy: 'startTime', sortDirection: 'asc' }
});

export const schedulingProjctLiveViewFilters = atom({
  key: 'schedulingProjctLiveViewFilters',
  default: {}
});

export const schedulingIndexShifts = atom({
  key: 'schedulingIndexShifts',
  default: []
});

export const schedulingIndexGroupsFilters = atom({
  key: 'schedulingIndexGroupsFilters',
  default: { filters: {}, groups: {} }
});

export const schedulingGroupRef = atom({
  key: 'schedulingGroupRef',
  default: null
});
