import React from 'react';
import styled, { css } from 'styled-components';
import { FlexColumn, FlexSpace } from '../Flex';
import { Header2 } from '../Typography';
import scrollbar from '../../style/scrollbar';

const Wrapper = styled(FlexColumn)`
  flex: 1;
`;

const TitleRow = styled(FlexSpace)`
  padding: 40px 50px 0 50px;
  z-index: 1;
  min-height: 30px;
`;

const Content = styled(FlexColumn)`
  flex: 1;
  padding: 40px 50px;

  ${({ $innerScroll }) =>
    $innerScroll &&
    css`
      overflow: auto;
      ${scrollbar};
    `};
`;

const Scrollable = styled.div`
  overflow: visible;
  height: 0;
`;

const SettingsPage = ({ title, actionBar, children, scrollable, innerScroll = true }) => {
  const Container = scrollable ? Scrollable : React.Fragment;
  return (
    <Wrapper>
      {title && (
        <TitleRow>
          <Header2>{title}</Header2>
          {actionBar}
        </TitleRow>
      )}

      <Content $innerScroll={innerScroll}>
        <Container>{children}</Container>
      </Content>
    </Wrapper>
  );
};

export default SettingsPage;
