import React, { useCallback, useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import ConfigContext from '../../contexts/ConfigContext';
import { TextInput } from '../Input';
import AutoCompleteOff from '../AutoCompleteOff';
import useMenu from '../../hooks/useMenu';
import BaseDateRangePicker from './DateRangePicker';

const Wrapper = styled.div`
  position: relative;
`;

const DateRangePicker = styled(BaseDateRangePicker)`
  z-index: 5;
  ${({ innerStyle }) =>
    css`
      ${innerStyle}
    `}
`;

const DateRangePickerInput = ({
  name,
  title,
  required,
  value,
  onChange,
  usePortal,
  isDisabled,
  openFromRight,
  error,
  withDay = false,
  showTotalDays = false,
  clearable = true,
  onClear: handleClearValue = undefined,
  className,
  readonly
}) => {
  const { t, dateFormat } = useContext(ConfigContext);

  const { Portal, wrapperRef, isOpen, setOpen, setClose, style, menuRef } = useMenu({ usePortal, openFromRight });

  const handleChange = useCallback(
    (dates) => {
      onChange(dates);
      dates?.startDate &&
        dates?.endDate &&
        moment(dates?.startDate).isValid() &&
        moment(dates?.endDate).isValid() &&
        dates?.startDate !== dates?.endDate &&
        setClose();
    },
    [onChange, setClose]
  );

  const handleInputChange = useCallback(
    (date) => {
      handleChange(moment(date, dateFormat).toISOString());
    },
    [dateFormat, handleChange]
  );

  const onClear = useCallback(
    () => (clearable ? (handleClearValue ? handleClearValue() : handleInputChange(null)) : null),
    [handleClearValue, clearable, handleInputChange]
  );

  const validValue = useMemo(
    () =>
      value &&
      moment(value?.startDate).isValid() &&
      moment(value?.endDate).isValid() && {
        startDate: moment(value.startDate, dateFormat).toISOString(),
        endDate: moment(value.endDate, dateFormat).toISOString()
      },
    [dateFormat, value]
  );

  const totalDays = useMemo(
    () =>
      showTotalDays && value?.startDate && moment(value?.startDate) && value?.endDate && moment(value?.endDate)
        ? `(${moment(value?.endDate).diff(moment(value?.startDate), 'days') + 1} ${t('days')})`
        : null,
    [showTotalDays, t, value?.endDate, value?.startDate]
  );

  const format = useMemo(() => (withDay ? `ddd, ${dateFormat}` : dateFormat), [dateFormat, withDay]);
  const mask = useMemo(() => (withDay ? undefined : '00/00/0000 - 00/00/0000'), [withDay]);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <AutoCompleteOff />
      <TextInput
        name={name}
        title={title}
        required={required}
        value={
          validValue
            ? `${moment(validValue.startDate).format(format)} - ${moment(validValue.endDate).format(format)} ${
                totalDays || ''
              }`
            : ''
        }
        onChange={handleInputChange}
        onFocus={setOpen}
        disabled={isDisabled}
        mask={mask}
        error={error}
        clearable={clearable}
        onClear={onClear}
        readonly={readonly}
      />

      {isOpen && (
        <Portal>
          <DateRangePicker
            startDate={value?.startDate}
            endDate={value?.endDate}
            onChange={handleChange}
            menuRef={menuRef}
            innerStyle={style}
          />
        </Portal>
      )}
    </Wrapper>
  );
};

export default DateRangePickerInput;
