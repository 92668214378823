import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { API_DATE_FORMAT } from '@ubeya/shared/constants';
import { isEmployeeCompliantToPosition } from '@ubeya/shared-web/utils/employee';
import branchManagerBadge from '@ubeya/shared-web/assets/branch-manager-badge.svg';
import accountManagerBadge from '@ubeya/shared-web/assets/account-manager-badge.svg';
import { flexCenter } from '../Flex';
import { Tooltip } from '../Tooltip';
import { ReactComponent as LogoIcon } from '../../assets/logo-empty.svg';
import ConfigContext from '../../contexts/ConfigContext';

const SIZES = {
  xs: { image: 20, font: 10 },
  small: { image: 24, font: 11 },
  medium: { image: 28, font: 13 },
  medium2: { image: 34, font: 13 },
  big: { image: 50, font: 13 },
  large: { image: 150, font: 52 }
};

const badgeStyle = css`
  content: '';
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: -4px;
  bottom: -2px;
`;

const Image = styled.div.attrs(({ image }) => ({ className: 'avatar', style: { backgroundImage: `url(${image})` } }))`
  position: relative;
  ${flexCenter};
  border-radius: 100%;
  width: ${({ size }) => SIZES[size]?.image || size}px;
  height: ${({ size }) => SIZES[size]?.image || size}px;
  min-width: ${({ size }) => SIZES[size]?.image || size}px;
  min-height: ${({ size }) => SIZES[size]?.image || size}px;
  background: #e9ebf0;
  background-size: cover;
  background-position: center;
  color: ${({ theme }) => theme.colors.primary};
  border: ${({ image }) => (image ? 0 : 1)} solid ${({ theme }) => theme.colors.gray800};
  text-align: center;
  font-weight: 500;
  font-size: ${({ size }) => SIZES[size]?.font}rem;
  ${({ onClick }) => onClick && 'cursor:pointer'};
  > svg {
    width: ${({ size }) => (SIZES[size]?.image || size) / 1.5}px;
    height: ${({ size }) => (SIZES[size]?.image || size) / 1.5}px;
  }

  ${({ $showBranchAdmin, $showAccountAdmin }) =>
    ($showAccountAdmin || $showBranchAdmin) &&
    css`
      &:after {
        background: url('${$showBranchAdmin ? branchManagerBadge : accountManagerBadge}');
        ${badgeStyle}
      }
    `}
`;

const PendingWarning = styled.div`
  position: absolute;
  right: -2px;
  top: -2px;
`;

const CircleChip = styled.div`
  min-width: 12px;
  height: 12px;
  display: flex;
  font-size: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: ${({ theme }) => theme.colors.surface};
  margin: 2px;
  background: ${({ theme }) => theme.colors.failure};
`;

const Avatar = ({
  firstName,
  lastName,
  isPending,
  warning,
  image,
  onClick,
  size = 'medium2',
  tooltip = false,
  tooltipBody,
  date,
  className,
  isAccount = false,
  employeeCompliance,
  showStar = false,
  showBranchAdmin = false
}) => {
  const name = tooltipBody || [firstName, lastName].join(' ');
  const { t } = useContext(ConfigContext);
  const innerData = image ? (
    <></>
  ) : isAccount ? (
    <LogoIcon />
  ) : firstName || lastName ? (
    [firstName?.charAt?.(0), lastName?.charAt?.(0)].join('')
  ) : (
    'N / A'
  );

  const { isCompliant, compliantExpDate } = useMemo(() => {
    if (!employeeCompliance) {
      // in en empty way
      return { isCompliant: true };
    }

    const isEmployeeCompliant = isEmployeeCompliantToPosition({ compliance: employeeCompliance, date });

    return {
      isCompliant: isEmployeeCompliant,
      compliantExpDate:
        !isEmployeeCompliant && !!employeeCompliance?.minExpirationDate
          ? moment(employeeCompliance.minExpirationDate).format(API_DATE_FORMAT)
          : null
    };
  }, [date, employeeCompliance]);

  return (
    <Tooltip body={name} disabled={!tooltip}>
      <Image
        image={image}
        className={className}
        size={size}
        $showAccountAdmin={showStar}
        $showBranchAdmin={showBranchAdmin}
        onClick={onClick}
        $showWarning={isPending}>
        {innerData}
        {isPending && (
          <PendingWarning>
            <Tooltip body={t('pendingApproval')}>
              <CircleChip>!</CircleChip>
            </Tooltip>
          </PendingWarning>
        )}

        {warning && (
          <PendingWarning>
            <Tooltip body={t(warning)}>
              <CircleChip>!</CircleChip>
            </Tooltip>
          </PendingWarning>
        )}

        {!isPending && !isCompliant && (
          <PendingWarning>
            <Tooltip
              body={`${t('notCompliant')} ${compliantExpDate ? `${t('compliantSince')} ${compliantExpDate}` : ''}`}>
              <CircleChip>!</CircleChip>
            </Tooltip>
          </PendingWarning>
        )}
      </Image>
    </Tooltip>
  );
};

export default Avatar;
