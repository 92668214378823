import React, { useCallback, useContext, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { changePageTitle } from '@ubeya/shared/hooks/usePageTitle';
import useAccount from '@ubeya/shared/hooks/account/useAccount';
import { ReactComponent as MenuIcon } from '@ubeya/shared-web/assets/expand.svg';
import { ReactComponent as ArrowIcon } from '@ubeya/shared-web/assets/minimize.svg';
import { FlexColumn, NavigationMenu, FlexCenter, FlexMiddle, Body } from '@ubeya/shared-web/components';
import { media } from '@ubeya/shared-web/style';
import { thinPrimaryScrollbar } from '@ubeya/shared-web/style/scrollbar';
import { Tooltip } from '../Tooltip';
import ConfigContext from '../../contexts/ConfigContext';

const Wrapper = styled(FlexColumn).attrs(() => ({ className: 'app-side-bar-wrapper' }))`
  position: relative;
  display: none;
  ${media.tablet`display: flex`};
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.mainSideBar};
  transition: 200ms;
  ${({ $isOpen }) => $isOpen && 'width: 250px;'}
`;

const CircleIcon = styled(FlexCenter)`
  border-radius: 8px;
  padding: 7px;
  cursor: pointer;
  transition: 200ms;
`;

const Label = styled(Body)`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.surface};
  transition: 200ms;
  font-weight: medium;
  font-size: 16rem;
  white-space: nowrap;
`;

const Link = styled(FlexMiddle)`
  text-decoration: none;
  padding: 10px;
  width: 100%;
  transition: 200ms;

  ${({ $userLink, $isOpen }) => !$userLink && !$isOpen && 'justify-content: center;'}

  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      > ${Label} {
        display: none;
      }
    `}


  &.active {
    ${({ $isOpen }) => $isOpen && `background: #dcd9ff38;`}
    > ${CircleIcon} {
      background: #6467ef;
    }
  }

  ${({ $mainIcon }) =>
    $mainIcon &&
    css`
      > ${CircleIcon} {
        padding: 0;
      }
    `}

  &:hover {
    ${({ $isOpen }) =>
      $isOpen
        ? css`
            background: #444677;
          `
        : css`
            > ${CircleIcon} {
              background: ${({ theme }) => theme.colors.primary};
            }
          `}
  }
`;

const ToggleButtonWrapper = styled(FlexCenter)`
  position: absolute;
  top: 80px;
  right: -20px;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 100%;
  padding: 2px;
  cursor: pointer;
  z-index: 4;
`;

const ToggleButton = styled(MenuIcon)`
  width: 30px;
  height: 30px;
  background: ${({ theme }) => theme.colors.primary};
  padding: 2px;
  border-radius: 100%;

  ${({ $isOpen, theme }) => ($isOpen ? `> path{fill: ${theme.colors.surface};}` : `stroke: ${theme.colors.surface};`)}
`;

const Links = styled(FlexColumn)`
  position: absolute;
  top: 0;
  bottom: 66px;
  left: 0;
  right: 0;
  overflow: hidden auto;
  ${thinPrimaryScrollbar};

  &:not(:first-child) {
    top: 120px;
  }
`;

const AppSideBarItem = ({
  to,
  Icon,
  mainIcon,
  t,
  label,
  isOpen,
  LinkRenderer,
  accountName,
  shouldChangePageTitle = true
}) => {
  const onClick = useCallback(() => shouldChangePageTitle && changePageTitle(accountName, label, t), [
    accountName,
    shouldChangePageTitle,
    label,
    t
  ]);
  return (
    <Tooltip body={t(label)} placement="right" disabled={isOpen}>
      <div className={label}>
        <Link as={LinkRenderer} to={to} $isOpen={isOpen} onClick={onClick} $mainIcon={mainIcon}>
          <CircleIcon>
            <Icon />
          </CircleIcon>
          <Label>{t(label)}</Label>
        </Link>
      </div>
    </Tooltip>
  );
};

const AppSideBar = ({
  links,
  linkRenderer: LinkRenderer = NavLink,
  children,
  mobileRenderer = children,
  shouldChangePageTitle = true
}) => {
  const { t } = useContext(ConfigContext);
  const [isOpen, setIsOpen] = useState(false);
  const {
    account: { name }
  } = useAccount();

  const flatLinks = useMemo(() => links.flat(), [links]);

  return (
    <>
      <NavigationMenu linkRenderer={LinkRenderer} links={flatLinks}>
        {React.cloneElement(mobileRenderer, { isMobile: true })}
      </NavigationMenu>

      <Wrapper $isOpen={isOpen}>
        <ToggleButtonWrapper onClick={() => setIsOpen(!isOpen)}>
          <ToggleButton $isOpen={isOpen} as={isOpen ? ArrowIcon : undefined} />
        </ToggleButtonWrapper>
        <FlexColumn>
          {links.map((subLinks, index) => (
            <Links key={index}>
              {subLinks
                .filter(({ isVisible = true }) => isVisible)
                .map(({ to, icon: Icon, mainIcon, label }, linkIndex) => (
                  <AppSideBarItem
                    mainIcon={mainIcon}
                    shouldChangePageTitle={shouldChangePageTitle}
                    key={linkIndex}
                    to={to}
                    Icon={Icon}
                    t={t}
                    label={label}
                    isOpen={isOpen}
                    LinkRenderer={LinkRenderer}
                    accountName={name}
                  />
                ))}
            </Links>
          ))}
        </FlexColumn>
        <Link $userLink>{children}</Link>
      </Wrapper>
    </>
  );
};

export default AppSideBar;
