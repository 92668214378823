import React, { useState, useCallback, useMemo, useContext } from 'react';
import styled from 'styled-components';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';
import { FlexCenter } from '../../Flex';
import { BOOKING_STATUS_MAPPING } from '../../../constants';

const StatusDot = styled.div`
  border-radius: 100%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background-color: ${({ $color }) => $color};
  border: 1px solid ${({ theme }) => theme.colors.gray000};
`;

const FormattedLabelWrapper = styled(FlexCenter)`
  gap: 2px;
`;

const FormattedLabel = ({ value, label }) => {
  const color = useMemo(() => {
    switch (value) {
      case 1:
        return '#E05B67';

      case 2:
        return '#F4B346';

      case 3:
        return '#10B07C';

      default:
        return 'E05B67';
    }
  }, [value]);

  return (
    <FormattedLabelWrapper>
      <StatusDot $color={color} />
      {label}
    </FormattedLabelWrapper>
  );
};

const ProjectLiveViewBookingStatusFilter = ({ defaultValue, handleClose, onApply, search, onChange }) => {
  const { t } = useContext(ConfigContext);
  const [values, setValues] = useState(defaultValue || []);

  const bookingStatusOptions = Object.entries(BOOKING_STATUS_MAPPING).map(([id, label]) => ({
    value: Number(id),
    label: t(label)
  }));

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      search
        ? (bookingStatusOptions || []).filter(
            ({ label }) =>
              t('bookingStatus').toLowerCase().includes(search.toLowerCase()) ||
              label.toLowerCase().includes(search.toLowerCase())
          )
        : bookingStatusOptions,
    [bookingStatusOptions, search, t]
  );

  if (!filteredOptions.length) {
    return null;
  }

  return (
    <FilterLayout
      name={t('bookingStatus')}
      handleClose={handleClose}
      onApply={() => onApply(values)}
      hideLayout={!!search}>
      {filteredOptions.map(({ value, label }) => (
        <DropDown.Item key={value}>
          <CheckBox
            name={`bookingStatus-${value}`}
            value={values.includes(value)}
            label={<FormattedLabel value={value} label={label} />}
            onChange={() => handleChange(value)}
          />
        </DropDown.Item>
      ))}
    </FilterLayout>
  );
};

export default ProjectLiveViewBookingStatusFilter;
