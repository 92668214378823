import React, { useState, useCallback, useEffect, useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Trans } from 'react-i18next';
import { Form } from 'react-final-form';
import usePrevious from '@ubeya/shared/hooks/usePrevious';
import { flexColumn, FlexMiddle, FlexColumn } from '../Flex';
import Button from '../Button';
import media from '../../style/media';
import BaseWizardBar from './WizardBar';
import WizardTopNavBar from './WizardTopNavBar';

const Container = styled.form`
  ${flexColumn};
  flex: 1;
`;

const WarningLine = styled.div`
  color: ${({ theme }) => theme.colors.failure};
  margin-right: auto;
  margin-left: -20px;
`;

const Buttons = styled(FlexMiddle)`
  justify-content: flex-end;
  border-top: ${({ theme }) => `1px solid ${theme.colors.modalBorder}`};
  padding: 7px 28px;

  > button {
    margin-left: 16px;
  }
`;

const WizardBar = styled(BaseWizardBar)`
  position: absolute;
  left: 42px;
  right: 42px;
  ${media.mobile`justify-content: center;`}
`;

const slideRight = keyframes`
  0% {transform: translateX(100%); opacity: 0;}
  100% {transform: translateX(0); opacity: 1;}
`;

const slideLeft = keyframes`
  0% {transform: translateX(-100%); opacity: 0;}
  100% {transform: translateX(0); opacity: 1;}
`;

const PageWrapper = styled(FlexColumn)`
  overflow: hidden;
  flex: 1;
  ${({ $active }) =>
    $active &&
    css`
      animation: ${({ $isRight }) => (!$isRight ? slideRight : slideLeft)} ease-out 300ms;
    `};
`;

const Wizard = ({
  initialValues,
  mutators,
  children,
  onSubmit,
  onClose,
  onPageChange,
  extraValidator,
  showPrevious = true,
  doneText = 'done',
  disabledOnInvalid = true,
  showTopNavBar = false,
  showOnlyCurrentNav = false,
  keepDirtyOnReinitialize = false,
  wizardTopNavTabs,
  renderExtraDoneButton,
  renderLeft,
  setControlledErrors = null,
  customDoneButton,
  buttonSubmitted,
  showDotsNavigation = true,
  requiredWarningFooter,
  showWarningOnSpecificPage = false
}) => {
  const [page, setPage] = useState(0);
  const [values, setValues] = useState(initialValues);
  const [dirtyFields, setDirtyFields] = useState({});

  const filteredChildren = useMemo(() => React.Children.toArray(children).filter(Boolean), [children]);

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const handleNext = useCallback(
    (valuesData, form) => {
      const newPage = Math.min(page + 1, filteredChildren.length - 1);
      setPage(newPage);
      setValues(valuesData);
      onPageChange?.(newPage, valuesData);
      setDirtyFields((prev) => ({ ...prev, ...form.getState().dirtyFields }));
    },
    [filteredChildren.length, onPageChange, page]
  );

  const handlePrevious = useCallback(() => {
    const newPage = Math.max(page - 1, 0);
    setPage(newPage);
    onPageChange?.(newPage);
  }, [onPageChange, page]);

  const validate = useCallback(
    (valuesData) => {
      const activePage = React.Children.toArray(filteredChildren)[page];

      if (activePage.props.validate) {
        return activePage.props.validate(valuesData);
      }

      if (extraValidator) {
        return extraValidator(valuesData, page);
      }

      return {};
    },
    [extraValidator, filteredChildren, page]
  );

  const handleSubmit = useCallback(
    (valuesData, form) => {
      const isLastPage = page === React.Children.count(filteredChildren) - 1;
      if (isLastPage) {
        return onSubmit(valuesData, { ...dirtyFields, ...form.getState().dirtyFields }, form);
      }
      return handleNext(valuesData, form);
    },
    [page, filteredChildren, handleNext, onSubmit, dirtyFields]
  );

  const activePage = React.Children.toArray(filteredChildren)[page];
  const isLastPage = page === React.Children.count(filteredChildren) - 1;

  const lastActivePage = usePrevious(page);

  return (
    <Form
      initialValues={values}
      keepDirtyOnReinitialize={keepDirtyOnReinitialize}
      validate={validate}
      mutators={mutators}
      onSubmit={handleSubmit}>
      {({ handleSubmit: handleSubmitFunction, submitting, valid, submitFailed, errors, values: formValues }) => {
        /* when the items inside the form, are not all field type,
          use this hack to catch errors when submittion failed,
          and display the error in the UI */
        if (submitFailed && setControlledErrors) {
          setControlledErrors(errors);
        }

        return (
          <Container
            onSubmit={(e) => {
              if (buttonSubmitted) {
                buttonSubmitted.current = 'main';
              }
              return handleSubmitFunction(e);
            }}>
            {showTopNavBar && (
              <WizardTopNavBar
                onClose={onClose}
                page={page}
                disabled={!valid || formValues.isLoading}
                wizardTopNavTabs={wizardTopNavTabs}
                setPage={setPage}
                showOnlyCurrent={showOnlyCurrentNav}
              />
            )}

            {React.cloneElement(activePage, {
              active: page !== 0 || lastActivePage !== 0,
              isRight: lastActivePage > page
            })}
            <Buttons className="buttons">
              {requiredWarningFooter && !valid && showWarningOnSpecificPage && page === showWarningOnSpecificPage && (
                <WarningLine>{requiredWarningFooter}</WarningLine>
              )}

              {renderLeft?.({ page, totalPages: React.Children.count(filteredChildren) })}

              {!showTopNavBar && showDotsNavigation && <WizardBar page={page} stepsCount={filteredChildren.length} />}

              {page > 0 && showPrevious && (
                <Button secondary type="button" onClick={handlePrevious}>
                  <Trans>previous</Trans>
                </Button>
              )}
              {!isLastPage && (
                <Button primary type="submit" disabled={!valid || formValues.isLoading} id="next-step">
                  <Trans>next</Trans>
                </Button>
              )}
              {isLastPage && (
                <>
                  {customDoneButton ? (
                    customDoneButton({
                      disabled: submitting || (disabledOnInvalid ? !valid : false),
                      isLoading: submitting && (!buttonSubmitted || buttonSubmitted?.current === 'main')
                    })
                  ) : (
                    <Button
                      primary={!renderExtraDoneButton}
                      secondary={renderExtraDoneButton}
                      type="submit"
                      disabled={submitting || (disabledOnInvalid ? !valid : false)}
                      isLoading={submitting && (!buttonSubmitted || buttonSubmitted?.current === 'main')}>
                      <Trans>{doneText}</Trans>
                    </Button>
                  )}

                  {renderExtraDoneButton?.({
                    disabled: submitting || (disabledOnInvalid ? !valid : false),
                    isLoading: submitting,
                    onClick: (e) => {
                      e.preventDefault();
                      if (buttonSubmitted) {
                        buttonSubmitted.current = 'external';
                      }
                      return handleSubmitFunction();
                    }
                  })}
                </>
              )}
            </Buttons>
          </Container>
        );
      }}
    </Form>
  );
};

Wizard.Page = ({ active, isRight, children }) => (
  <PageWrapper $active={active} $isRight={isRight}>
    {children}
  </PageWrapper>
);

export default Wizard;
