import React, { useMemo, useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import useFilters from '@ubeya/shared/hooks/account/useFilters';
import useFields, { FieldTypes } from '@ubeya/shared/hooks/account/useFields';
import { FILTERS_IDS } from '@ubeya/shared/constants';
import DropDown from '../DropDown';
import { Small } from '../Typography';
import { flexColumnCenter } from '../Flex';
import BaseSearch from '../Search';
import { counterFormatter, formatOptions, applyFilter, applyFilters } from '../../utils/filters';
import ConfigContext from '../../contexts/ConfigContext';
import { CHAT_FILTERS_SLUGS, PROJECT_LIVE_VIEW_FILTERS_SLUGS, PROJECT_SCHEDULING_FILTERS_SLUGS } from '../../constants';
import PositionsFilter from './CustomFilters/PositionsFilter';
import AgeFilter from './CustomFilters/AgeFilter';
import RatingFilter from './CustomFilters/RatingFilter';
import FieldsFilter from './CustomFilters/FieldsFilter';
import EmployeeTypeFilter from './CustomFilters/EmployeeTypeFilter';
import AdminTypeFilter from './CustomFilters/AdminTypeFilter';
import AdminFilter from './CustomFilters/AdminFilter';
import TagsFilter from './CustomFilters/TagsFilter';
import ClientsFilter from './CustomFilters/ClientsFilter';
import PreferredClientsFilter from './CustomFilters/PreferredClientsFilter';
import BranchesFilter from './CustomFilters/BranchesFilter';
import ApprovalFilter from './CustomFilters/ApprovalFilter';
import SuppliersFilter from './CustomFilters/SuppliersFilter';
import WorkedWithClientFilter from './CustomFilters/WorkedWithClientFilter';
import LocationsFilter from './CustomFilters/LocationsFilter';
import DistanceFromLocationFilter from './CustomFilters/DistanceFromLocationFilter';
import HapAppFilter from './CustomFilters/HasAppFilter';
import SearchableOptions from './SearchableOptions';
import DateFilter from './CustomFilters/DateFilter';
import BookingStatusFilter from './CustomFilters/BookingStatusFilter';
import OrderStatusFilter from './CustomFilters/OrderStatusFilter';
import ProjectLiveViewPositionsFilter from './CustomFilters/ProjectLiveViewPositionsFilter';
import ProjectLiveViewLocationsFilter from './CustomFilters/ProjectLiveViewLocationsFilter';
import ProjectLiveViewStartTimeFilter from './CustomFilters/ProjectLiveViewStartTimeFilter';
import ProjectLiveViewEndTimeFilter from './CustomFilters/ProjectLiveViewEndTimeFilter';
import ProjectLiveViewSuppliersFilter from './CustomFilters/ProjectLiveViewSuppliersFilter';
import ProjectLiveViewBookingStatusFilter from './CustomFilters/ProjectLiveViewBookingStatusFilter';
import ChatTagsFilter from './CustomFilters/ChatTagsFilter';
import ChatBranchesFilter from './CustomFilters/ChatBranchesFilter';
import ChatStatusesFilter from './CustomFilters/ChatStatusesFilter';
import CostCenterFilter from './CustomFilters/CostCenterFilter';

const EmptyComponentRenderer = () => <></>;

const COMPONENTS_MAP = {
  positions: PositionsFilter,
  age: AgeFilter,
  rating: RatingFilter,
  employeeType: EmployeeTypeFilter,
  adminType: AdminTypeFilter,
  eventBooker: AdminFilter,
  restrictedClient: ClientsFilter,
  tags: TagsFilter,
  clients: ClientsFilter,
  costCenter: CostCenterFilter,
  suppliers: SuppliersFilter,
  approved: ApprovalFilter,
  locations: LocationsFilter,
  hasApp: HapAppFilter,
  workedWithClient: WorkedWithClientFilter,
  notBookedOnDate: DateFilter,
  branches: BranchesFilter,
  preferredClients: PreferredClientsFilter,
  startEmploymentDate: DateFilter,
  bookingStatus: BookingStatusFilter,
  availableByTimeAvailability: EmptyComponentRenderer,
  orderStatusFilter: OrderStatusFilter,

  [PROJECT_LIVE_VIEW_FILTERS_SLUGS.positions]: ProjectLiveViewPositionsFilter,
  [PROJECT_LIVE_VIEW_FILTERS_SLUGS.locations]: ProjectLiveViewLocationsFilter,
  [PROJECT_LIVE_VIEW_FILTERS_SLUGS.startTime]: ProjectLiveViewStartTimeFilter,

  [PROJECT_SCHEDULING_FILTERS_SLUGS.positions]: ProjectLiveViewPositionsFilter,
  [PROJECT_SCHEDULING_FILTERS_SLUGS.locations]: ProjectLiveViewLocationsFilter,
  [PROJECT_SCHEDULING_FILTERS_SLUGS.startTime]: ProjectLiveViewStartTimeFilter,
  [PROJECT_SCHEDULING_FILTERS_SLUGS.endTime]: ProjectLiveViewEndTimeFilter,
  [PROJECT_SCHEDULING_FILTERS_SLUGS.bookingStatus]: ProjectLiveViewBookingStatusFilter,
  [PROJECT_SCHEDULING_FILTERS_SLUGS.suppliers]: ProjectLiveViewSuppliersFilter,

  [CHAT_FILTERS_SLUGS.tags]: ChatTagsFilter,
  [CHAT_FILTERS_SLUGS.branches]: ChatBranchesFilter,
  [CHAT_FILTERS_SLUGS.statuses]: ChatStatusesFilter
};

const Search = styled(BaseSearch)`
  min-height: 40px;
`;

const Counter = styled(Small)`
  ${flexColumnCenter};
  width: 17px;
  height: 14px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.gray400};
  color: ${({ theme }) => theme.colors.surface};
  margin-left: 7px;
`;

const Clear = styled(Small)`
  color: ${({ theme }) => theme.colors.primary};
  white-space: nowrap;
  cursor: pointer;
  margin-left: 9px;
  margin-top: 10px;
`;

const FilterOptions = ({
  selectedKey,
  showSearchBox,
  search,
  setSearch,
  filters,
  setFilters,
  selectedOpenFilter,
  filterContextParams,
  disabledFilters,
  externalOptions
}) => {
  const { t } = useContext(ConfigContext);
  const { filters: filtersData } = useFilters();
  const { mappedFields } = useFields();
  const [openFilter, setOpenFilter] = useState(selectedOpenFilter);

  const basicOptions = useMemo(
    () =>
      formatOptions({ options: filtersData[selectedKey], type: 'basic', filters }).filter(({ slug: filterSlug }) => {
        if (externalOptions?.[filterSlug]?.length === 0) return false;
        return ![
          'bookedInProjects',
          'restrictedClient',
          'isShiftType',
          'notBookedOnDate',
          'availableByTimeAvailability'
        ].includes(filterSlug);
      }),
    [externalOptions, filtersData, filters, selectedKey]
  );

  const fieldsOptions = useMemo(
    () =>
      formatOptions({
        options: filtersData[selectedKey],
        type: 'fields',
        formatSlug: (id) => mappedFields?.[id]?.name,
        filters
      }),
    [filtersData, filters, mappedFields, selectedKey]
  );

  const getCounter = useCallback(
    (filter) => (counterFormatter(filter.value) ? <Counter>{counterFormatter(filter.value)}</Counter> : null),
    []
  );

  const handleApply = useCallback(
    (value) => {
      const { type, id, slug } = openFilter;

      setFilters(applyFilter({ type, id, value, slug, allFilters: filters }));
      !selectedOpenFilter && setOpenFilter();
    },
    [filters, openFilter, selectedOpenFilter, setFilters]
  );

  const handleApplyAll = useCallback(
    (values) => {
      setFilters(applyFilters(values));
      setSearch('');
    },
    [setFilters, setSearch]
  );

  const mappedBasicOptions = useMemo(
    () =>
      (filtersData[selectedKey]?.basic || []).reduce((all, curr) => {
        all[curr.id] = curr;
        return all;
      }, {}),
    [filtersData, selectedKey]
  );

  const handleResetFilters = useCallback(() => {
    const postClearFilters = {
      basic: (filters?.basic || []).filter(({ id }) =>
        mappedBasicOptions?.[id]?.slug ? (disabledFilters || []).includes(mappedBasicOptions[id].slug) : false
      )
    };

    setFilters(postClearFilters);
  }, [disabledFilters, filters?.basic, mappedBasicOptions, setFilters]);

  const hasFilters = useMemo(() => filters?.basic?.length > 0 || filters?.fields?.length > 0, [
    filters?.basic?.length,
    filters?.fields?.length
  ]);

  if (openFilter) {
    const Component =
      COMPONENTS_MAP[openFilter.slug] ||
      (FieldTypes &&
      !!mappedFields[openFilter.id]?.fieldTypeId &&
      mappedFields[openFilter.id].fieldTypeId === FieldTypes.FIELD_TYPE_ADDRESS
        ? DistanceFromLocationFilter
        : FieldsFilter);

    return (
      <>
        <Component
          id={openFilter.id}
          slug={openFilter.slug}
          defaultValue={openFilter.value}
          handleClose={selectedOpenFilter ? undefined : () => setOpenFilter()}
          onApply={handleApply}
          filterContextParams={filterContextParams}
          isDisabled={openFilter.id !== FILTERS_IDS.START_EMPLOYMENT_DATE}
          basicFilters={filters?.basic || []}
          externalOptions={externalOptions}
        />
      </>
    );
  }

  return (
    <>
      {showSearchBox && <Search value={search} onChange={setSearch} keepOpen />}
      {hasFilters && <Clear onClick={handleResetFilters}>{t('clear')} (x)</Clear>}
      {search ? (
        <SearchableOptions
          search={search}
          basicOptions={basicOptions}
          fieldsOptions={fieldsOptions}
          COMPONENTS_MAP={COMPONENTS_MAP}
          onApply={handleApplyAll}
          externalOptions={externalOptions}
        />
      ) : (
        <>
          {basicOptions.map((filter, index) => (
            <DropDown.Item
              key={index}
              useTextWithTooltip
              onClick={() => setOpenFilter(filter)}
              closeOnClick={false}
              isDisabled={disabledFilters.includes(filter.slug)}>
              {t(filter.slug)} {getCounter(filter)}
            </DropDown.Item>
          ))}

          {basicOptions.length > 0 && fieldsOptions.length > 0 && <DropDown.Separator />}

          {fieldsOptions.map((filter, index) => (
            <DropDown.Item
              key={index}
              useTextWithTooltip
              onClick={() => setOpenFilter(filter)}
              closeOnClick={false}
              isDisabled={disabledFilters.includes(filter.slug)}>
              {t(filter.slug)} {getCounter(filter)}
            </DropDown.Item>
          ))}
        </>
      )}
    </>
  );
};

export default FilterOptions;
