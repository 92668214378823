import { get } from 'lodash';
import { isValidNumber, parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { API_DATE_TIME_FORMAT } from '@ubeya/shared/constants';

export const required = (value, falseInclude = false) =>
  (Array.isArray(value) && value.length === 0) ||
  value === '' ||
  value === undefined ||
  value === null ||
  (falseInclude && value === false)
    ? 'required'
    : undefined;

export const requiredFilters = (value) => (!value || !value?.fields?.length ? 'required' : undefined);

export const onlyPositiveNumber = (value) => (!Number.isNaN(parseFloat(value)) ? Math.abs(value) : value);

export const startsWithCapital = (value) => `${value.charAt(0).toUpperCase()}${value.slice(1)}`;

export const validPhoneNumber = (value, countryCode) => {
  if (!value) {
    return undefined;
  }

  if (countryCode) {
    return !isValidNumber(value?.toString?.() || '', countryCode.toUpperCase()) ? 'invalidPhone' : undefined;
  }

  try {
    return parsePhoneNumber(`+${value}`)?.isValid() ? undefined : 'invalidPhone';
  } catch (err) {
    return 'invalidPhone';
  }
};

export const isValidLink = (link) => {
  const urlRegex = /^(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
  return urlRegex.test(link);
};

export const isEmailValid = (value) =>
  !value ||
  (value?.toString?.() || '').match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
    ? true
    : false;

export const validEmail = (value) => (isEmailValid(value) ? undefined : 'invalidEmail');

export const validBirthdate = (value, isRequired = false) =>
  (!isRequired && !value) ||
  (moment().diff(moment(value, API_DATE_TIME_FORMAT), 'years') > 0 &&
    moment().diff(moment(value, API_DATE_TIME_FORMAT), 'years') <= 120)
    ? undefined
    : 'invalidBirthdate';

export const validLength = (limit) => (value) => (value.length > limit ? 'tooLarge' : undefined);

export const validPassword = (value) => (!value || value.length < 8 ? 'invalidPassword' : undefined);

/* Passwords must include at least one character from one of the following categories - 
Uppercase letter, Lowercast letter, digits, special character (!, $, # etc)  and be min of 12 chars length 
*/
export const authPasswordValidation = (value) => {
  //min of 12 chars length
  if (!value || value.length < 8) {
    return 'passwordErrorNotEnoughChar';
  }

  return false;
};

export const passwordIndicator = (password) => {
  if (validPassword(password)) {
    return;
  }

  const strongRegex = new RegExp('^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$', 'g');
  const mediumRegex = new RegExp(
    '^(?=.{10,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$',
    'g'
  );

  if (strongRegex.test(password)) {
    return 'strongPassword';
  }

  if (mediumRegex.test(password)) {
    return 'mediumPassword';
  }

  return 'weekPassword';
};

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

const IGNORE_SPLIT_ARRAY = ['branches', 'positions', 'workDays', 'tags', 'preferredClients', 'blockedClients', 'links'];

export const formatFieldsArrayToMap = (data = {}, generatePayrollFields = {}) => {
  return {
    ...data,
    fields: (data.fields || []).reduce((prev, { id, value }) => ({ ...prev, [`field-${id}`]: value }), {}),
    payrollFields: (generatePayrollFields || []).reduce(
      (prev, { id, value }) => ({ ...prev, [`field-${id}`]: value }),
      {}
    )
  };
};

export const formatPrivilegesArrayToMap = (admin = {}) => ({
  privilegesMap: admin.privileges?.reduce?.((prev, { id }) => ({ ...prev, [`privilege-${id}`]: true }), {}) || {}
});

// split array to added/edited/deleted arrays
const splitDirtyArrayToStatus = (key, values) => ({
  added: get(values, key)
    .filter(({ status }) => status === 'added')
    .map((item) => (item.id < 0 ? { ...item, id: undefined } : item)),
  deleted: get(values, key)
    .filter(({ status }) => status === 'deleted')
    .map(({ id }) => id),
  edited: get(values, key).filter(({ status }) => status === 'edited')
});

export const getDirtyFields = (values, dirtyFields) =>
  Object.entries(dirtyFields)
    .filter(([, value]) => value)
    .reduce((prev, [key]) => {
      if (key.includes('fields.field-')) {
        const [, fieldId] = new RegExp(`fields.field-(.*)`).exec(key);
        return { ...prev, fields: [...(prev.fields || []), { id: fieldId, value: get(values, key) || '' }] };
      }

      if (key.includes('payrollFields.field-')) {
        const [, fieldId] = new RegExp(`payrollFields.field-(.*)`).exec(key);
        return {
          ...prev,
          payrollFields: [
            ...(prev.payrollFields || []),
            { id: parseInt(fieldId, 10), value: Number(get(values, key)) || 0 }
          ]
        };
      }

      if (!IGNORE_SPLIT_ARRAY.includes(key) && Array.isArray(values[key])) {
        const arraySplitting = splitDirtyArrayToStatus(key, values);
        return { ...prev, [key]: { ...prev[key], ...arraySplitting } };
      }

      if (key === 'wages.hourly') {
        const wagesHourly = splitDirtyArrayToStatus(key, values);
        return { ...prev, wages: { ...prev.wages, hourly: { ...(prev.wages?.hourly || {}), ...wagesHourly } } };
      }

      if (key === 'wages.global') {
        const wagesGlobal = splitDirtyArrayToStatus(key, values);
        return { ...prev, wages: { ...prev.wages, global: { ...(prev.wages?.global || {}), ...wagesGlobal } } };
      }

      if (key.includes('config.')) {
        const [, configKey] = new RegExp(`config.(.*)`).exec(key);
        return { ...prev, [configKey]: get(values, key) };
      }

      if (key.includes('mobileClockMethods.')) {
        return prev;
      }

      if (key.includes('privilegesMap')) {
        return prev;
      }

      return { ...prev, [key]: get(values, key, '') };
    }, {});
