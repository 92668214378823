import React, { useContext } from 'react';
import moment from 'moment';
import { FlexMiddle } from '../../Flex';
import ConfigContext from '../../../contexts/ConfigContext';

const StartTimeChip = ({ value }) => {
  const { timeFormat } = useContext(ConfigContext);

  if (!Array.isArray(value)) {
    return <FlexMiddle>{moment(value).format(timeFormat)}</FlexMiddle>;
  }

  return <FlexMiddle>{value.map((item) => moment(item).format(timeFormat)).join(', ')}</FlexMiddle>;
};

export default StartTimeChip;
