import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import ConfigContext from '../../contexts/ConfigContext';
import { FlexCenter } from '../Flex';
import Select from '../Select';
import { semiExtraSmall } from '../Typography/Typography';

const Wrapper = styled.div`
  position: relative;

  .CalendarDay__default {
    border: none;
    ${semiExtraSmall}
  }

  .CalendarDay__today:not(.CalendarDay__highlighted_calendar) {
    background-color: ${({ theme }) => theme.colors.secondary}1f;
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
    font-weight: bold;
  }
`;

const Header = styled(FlexCenter)`
  margin-top: -4px;
  z-index: 1;

  > div:first-child {
    width: 110px;
    margin-right: 5px;
  }
  > div:last-child {
    width: 70px;
  }

  > div .select-input,
  > div .select-label {
    min-height: 33px;
    padding: 0 7px;
  }

  > div .select-label {
    line-height: 32px;
  }

  > div .select-arrow {
    right: 10px;
  }
`;

const MONTHS = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

const DatePicker = ({ value, onChange, className, menuRef, style, renderSelects = true, ...restProps }) => {
  const { t } = useContext(ConfigContext);
  const { isRtl } = useContext(ConfigContext);

  const months = useMemo(() => MONTHS.map((month, index) => ({ label: t(`long_${month}`), value: index })), [t]);

  const years = useMemo(
    () =>
      [...Array(200)].map((_, index) => ({
        label: (moment().year() - index + 100).toString(),
        value: (moment().year() - index + 100).toString()
      })),
    []
  );

  return (
    <Wrapper className={className} ref={menuRef} style={style}>
      <DayPickerSingleDateController
        date={value ? moment(value) : moment()}
        onDateChange={(date) => onChange(date.toISOString())}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        focused
        transitionDuration={0}
        isRTL={isRtl}
        renderWeekHeaderElement={(day) => t(day.toLowerCase())}
        renderMonthElement={
          !renderSelects
            ? undefined
            : ({ month, onMonthSelect, onYearSelect }) => {
                const selectedMonth = month.month();
                const selectedYear = month.format('YYYY');
                return (
                  <Header>
                    <Select
                      value={selectedMonth}
                      onChange={(newMonth) => onMonthSelect(month, newMonth)}
                      options={months}
                      usePortal
                    />
                    <Select
                      value={selectedYear}
                      onChange={(newYear) => onYearSelect(month, newYear)}
                      options={years}
                      usePortal
                    />
                  </Header>
                );
              }
        }
        {...restProps}
      />
    </Wrapper>
  );
};

export default DatePicker;
