import React, { useContext, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FlexColumnCenter, FlexSpace } from '../Flex';
import { card } from '../Card';
import { body } from '../Typography';
import { ReactComponent as NavigationIcon } from '../../assets/navigation.svg';
import { media, responsiveHide } from '../../style';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import ConfigContext from '../../contexts/ConfigContext';

const TopBar = styled(FlexSpace)`
  ${media.tablet`display: none`}
  background: ${({ theme }) => theme.colors.backgroundDark};
  padding: 18px;
`;

const MenuIcon = styled(NavigationIcon)`
  ${responsiveHide.tablet}
  cursor:pointer;
`;

const enter = keyframes`
	0%{transform: translateX(-100vw)}
	100%{transform: translateX(0px)}
`;

const Menu = styled(FlexColumnCenter)`
  ${card};
  ${responsiveHide.tablet}
  animation: ${enter} ease-out 200ms;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 95vw;
  max-width: 300px;
  z-index: 5;

  &&& {
    a {
      ${body};
      background: transparent;
      color: ${({ theme }) => theme.colors.gray200};
      text-decoration: none;

      &.active {
        color: ${({ theme }) => theme.colors.link};
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
`;

const NavigationMenu = ({ linkRenderer: LinkRenderer, links, children }) => {
  const { t } = useContext(ConfigContext);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  const formattedLinks = links
    .filter(({ isVisible = true }) => isVisible)
    .map(({ label, to }) => (
      <LinkRenderer key={to} to={to} onClick={() => setIsOpen(false)}>
        {t(label)}
      </LinkRenderer>
    ));

  return (
    <TopBar>
      <MenuIcon onClick={() => setIsOpen(true)} />
      {children}

      {isOpen && (
        <Menu ref={ref}>
          <FlexColumnCenter>{formattedLinks}</FlexColumnCenter>
        </Menu>
      )}
    </TopBar>
  );
};

export default NavigationMenu;
