import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import MaskInput from 'react-maskinput';
import { ReactComponent as EditIcon } from '@ubeya/shared-web/assets/edit.svg';
import { ReactComponent as BaseClearIcon } from '@ubeya/shared-web/assets/close.svg';
import { ReactComponent as BaseVisibleEyeIcon } from '@ubeya/shared-web/assets/eye-visible.svg';
import { ReactComponent as BaseHiddenEyeIcon } from '@ubeya/shared-web/assets/eye-hidden.svg';

import Label from '../Label';
import CircleIcon from '../CircleIcon';
import { URL_REGEX } from '../../utils/employee';
import { baseInputStyle } from './InputStyle';
import ErrorText from './ErrorText';

const PREVENT_CHROME_AUTO_COMPLETE_FIELD = ['date', 'address'];

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;

  &:hover {
    .text-input-clear-icon {
      visibility: visible;
    }
`;

const EditIconContainer = styled.div`
  flex: 1;
`;

const InnerInputContainer = styled.div`
  position: relative;
  width: 100%;

  .error-text-wrapper {
    position: absolute;
    bottom: -15px;
  }
`;

const Input = styled.input`
  ${baseInputStyle};
`;

const ClearIcon = styled(BaseClearIcon).attrs(() => ({ className: 'text-input-clear-icon' }))`
  position: absolute;
  right: 3px;
  top: 8px;
  width: 11px;
  height: 19px;
  padding: 5px 6px;
  border-left: 1px solid ${({ theme }) => theme.colors.gray800};
  cursor: pointer;
  ${({ visible }) => !visible && `visibility: hidden`}
`;

const EyeVisibleIcon = styled(BaseVisibleEyeIcon)`
  position: absolute;
  right: 3px;
  top: 8px;
  width: 20px;
  height: 20px;
  padding: 5px 6px;
  cursor: pointer;
`;

const EyeHiddenIcon = styled(BaseHiddenEyeIcon)`
  position: absolute;
  right: 3px;
  top: 8px;
  width: 20px;
  height: 20px;
  padding: 5px 6px;
  cursor: pointer;
`;

const TextInput = ({
  title,
  name,
  type = 'text',
  value,
  onChange,
  className,
  required,
  mask,
  maxLength,
  autoFocus,
  error,
  onEditClick,
  linkTo,
  linkText,
  clearable = false,
  onClear = undefined,
  hideAutoComplete = false,
  enableShowLinks = false,
  ...inputProps
}) => {
  const [passwordType, setPasswordType] = useState(type);
  const onShowHidePassword = useCallback((e) => {
    e.stopPropagation();
    setPasswordType((prev) => (prev === 'password' ? 'text' : 'password'));
  }, []);

  const passwordTextInputType = useMemo(() => type === 'password', [type]);

  const { showLinks, links = [] } = useMemo(() => {
    if (!enableShowLinks) {
      return { showLinks: false };
    }

    if (!value || typeof value != 'string') {
      return { showLinks: false };
    }

    return {
      showLinks: true,
      links: value.match(URL_REGEX) || []
    };
  }, [enableShowLinks, value]);

  const handleValueChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      const formattedNewValue = type === 'number' ? Number(newValue) : newValue;

      return onChange(formattedNewValue);
    },
    [onChange, type]
  );

  return (
    <Label
      title={title}
      required={required}
      className={className}
      linkTo={linkTo}
      linkText={linkText}
      links={links}
      showLinks={showLinks}>
      <InputContainer>
        <InnerInputContainer>
          <Input
            type={passwordType}
            id={name}
            value={value}
            onChange={handleValueChange}
            as={mask ? MaskInput : undefined}
            mask={mask}
            maxLength={maxLength}
            autoFocus={autoFocus}
            autoComplete={
              hideAutoComplete || PREVENT_CHROME_AUTO_COMPLETE_FIELD?.includes(name) ? 'off' : 'new-password'
            }
            error={error ? 1 : 0}
            step="any"
            onWheel={(e) => e.target.blur()}
            {...inputProps}
          />

          {!!error && <ErrorText error={error} />}
        </InnerInputContainer>

        {passwordTextInputType &&
          !clearable &&
          (passwordType === 'password' ? (
            <EyeVisibleIcon onClick={onShowHidePassword} />
          ) : (
            <EyeHiddenIcon onClick={onShowHidePassword} />
          ))}

        {!!value && clearable && !inputProps?.isDisabled && (
          <ClearIcon
            visible={inputProps?.visible}
            onClick={(e) => {
              e.stopPropagation();
              onClear?.();
            }}
          />
        )}

        {!!onEditClick && (
          <EditIconContainer>
            <CircleIcon icon={EditIcon} onClick={onEditClick} />
          </EditIconContainer>
        )}
      </InputContainer>
    </Label>
  );
};

export default TextInput;
